/** @format */
import React, { useState } from 'react';
import { privacyString } from "./../extension/stringSave";
import { addFeedback } from "./../firebase/supportFirebase";

import "./../style/support.css";
export const Feedback = () => {
  const [textInput, setTextInput] = useState('');
  const [textPlaceholder, setTextPlaceHolder] = useState('Share your opinions');
  const enterButton = () => {
    const textInput = document.getElementById("text").value.trim();

    if (textInput === "") {
      // The input is empty
      setTextPlaceHolder('Text is empty');
    } else {
      setTextInput('');
      addFeedback(textInput);
    }
  };
  return (
    <div className="supportEditDiv">
      <p className="supportTitle">Feedback</p>
      <textarea
        id="text"
        className="supportInput"
        type="text"
        name="text"
        placeholder={textPlaceholder}
        onChange={(e) => setTextInput(e.target.value)}
        value={textInput}
      />
      <button
        id="supportButton"
        className="supportButton"
        onClick={enterButton}
      >
        Submit feedback
      </button>
    </div>
  );
};

export default Feedback;
