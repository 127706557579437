/** @format */

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { tName, copyRightLabel, stripePath} from "./../extension/stringSave";
import { goToListing } from "./../navigate/navigations";
import { getCart, deleteCart, loopCart } from './../firebase/cartFirebase';
import { getUser } from './../firebase/userFirebase';
import { auth } from './../firebase/firebase';

import { convertPrice2 } from './../extension/convertPrice'

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./CheckoutForm";
import TableComponent from "./../extension/tableComponent";
import MinibarComponent from "./../extension/minibarComponent";
import QuantityDropdown from "./../extension/quantityDropdown";
import useScrollTop from "./../extension/scrollTop";

// import './../firebase/stripeFirebase';

import "./../style/minibar.css";
import "./../style/footer.css";
import "./../style/checkout.css";

// checkout
import { addOrder } from './../firebase/orderFirebase';
import { uniqueId } from './../extension/uniqueID';
import { formattedDate } from './../extension/tempDate';

const Checkout = () => {
  const navigate = useNavigate();
  const [cartArray, setCartArray] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [totalString, setTotalString] = useState("0");
  const [priceString, setPriceString] = useState("0.00");
  const [username, setUsername] = useState("");
  const [deliveryString, setDeliveryString] = useState("Add your address");
  const [selectedItems, setSelectedItems] = useState(Array(cartArray.length).fill(false));

  useScrollTop();

  const stripePromise = loadStripe('pk_live_51ODnU1IrER54dJX74udWGwJUrejIwCA5CBpHwdT4al6b7qTmolKMacHAht3VnC4A5hCsJutO2K1sXc5BJavJNJ8s00TUgCoPGp');
  const [clientSecret, setClientSecret] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const [userDetails, setUserDetails] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          getCart()
          .then((cartData) => {
            setCartArray(cartData);
            setTotalString(cartData.length);
            const totalPrice = cartData.reduce((total, item) => total + parseFloat(item.price), 0);
            const decimalFixed = totalPrice.toFixed(2);
            setPriceString(decimalFixed)
          })
          .catch((error) => {
            console.error(error);
          });
          getUser()
          .then((userData) => {
            setUsername(userData.fullname)
            if(userData.delivery.trim() != ""){
              setDeliveryString(userData.delivery);
            }
            
          })
          .catch((error) => {
            console.error(error);
          });
        }
      });

      return () => unsubscribe(); // Cleanup the listener when the component unmounts
    };
    fetchData();
  }, []);

  const handleDelete = (itemId) => {
    const cartItemKey = cartArray[itemId].id
    deleteCart(cartItemKey)
    
    let total = parseFloat(priceString) - parseFloat(cartArray[itemId].price);
    let decimalFixed = total.toFixed(2);
    setTotalString(totalString - 1);
    setPriceString(decimalFixed);

    cartArray.splice(itemId, 1);
    setCartArray([...cartArray]);
  };

  const handleSelect = (index, priceIndex) => {
    const updatedSelectedItems = [...selectedItems];
    updatedSelectedItems[index] = !updatedSelectedItems[index];
    setSelectedItems(updatedSelectedItems);
  
    if (updatedSelectedItems[index]) {
      let total = parseFloat(priceString) - parseFloat(priceIndex);
      let decimalFixed = total.toFixed(2);
      setTotalString(totalString - 1);
      setPriceString(decimalFixed);
    } else {
      let total = parseFloat(priceString) + parseFloat(priceIndex);
      let decimalFixed = total.toFixed(2);
      setTotalString(totalString + 1);
      setPriceString(decimalFixed);
    }

  };

  const updateCart = () => {
    if(deliveryString != "Add your address"){
      setUpdatedData(loopCart(selectedItems, cartArray));
      createPayment();
    }else{
      changeAddress();
    }
  };
  
  const changeAddress = () => {
    let path = `/address`;
    navigate(path);
  } 

  const createPayment = () => {
    const url = stripePath;

    const updatedPrice = '1000';
    const data = {
      price: updatedPrice, // Replace with the actual username
    };

    fetch(url, {
      method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    }).then(async (result) => {
      var { clientToken } = await result.json();
      setClientSecret(clientToken);
      openDialog();
    });
  }

  const openDialog = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  return (
    <div>
      <MinibarComponent title={tName} />
      <div className="checkoutDiv">
        <table className="checkoutTable">
          <tbody>
            {cartArray.map((carts, index) => (
              <tr className="checkoutRow">
                <div className="checkoutHeader">
                  <div className="checkoutHeaderDiv">
                    <div className="checkoutHeaderDivLeft">
                      <div className="checkoutHeaderLabel">
                        <p className="checkoutLabel">Availability</p>
                        <p className="checkoutLabel2">In stock</p>
                      </div>
                      <div className="checkoutHeaderLabel">
                        <p className="checkoutLabel">Price</p>
                        <p className="checkoutLabel2">${convertPrice2(carts.price)}</p>
                      </div>

                      <div className="checkoutHeaderLabel">
                        <p className="checkoutLabel">Quantity</p>
                        <p className="checkoutLabel2">{carts.quantity}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="checkoutBody">
                  <div className="checkoutBodyDivLeft">
                    <img
                      id="checkoutIcon"
                      className="checkoutIcon"
                      src={carts.image} 
                      onClick={() => {if (carts.name.includes("Shawl")) {
                          goToListing("Shawl", carts.name, navigate);
                        } else {
                          goToListing("Carpet", carts.name, navigate);
                        }
                      }}
                    ></img>

                    <div className="checkoutTextDiv">
                      <p
                        className="checkoutUsername"
                          onClick={() => {if (carts.name.includes("Shawl")) {
                            goToListing("Shawl", carts.name, navigate);
                          } else {
                            goToListing("Carpet", carts.name, navigate);
                          }
                        }}
                      >
                       {carts.name}
                      </p>
                    </div>
                  </div>

                  <div className="checkoutBodyDivRight">
                    <button className="checkoutUpdate" onClick={() => handleDelete(index)}>Delete</button>
                    <button className={selectedItems[index] ? 'checkoutUpdate' : 'checkoutSelected'} onClick={() => handleSelect(index, carts.price)}>
                      {selectedItems[index] ? 'Select' : 'Selected'}
                    </button>
                  </div>
                </div>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="checkoutPurchaseDiv">
        <div className="checkPurchaseLabels">
          <p className="checkPurchaseLabel">Delivery Address</p>
          <p className="checkPurchaseLabel2">{deliveryString}</p>
          <p className="checkPurchaseLabel3" onClick={changeAddress}>Change delivery address</p>
        </div>
        <div className="checkPurchaseLabels">
          <p className="checkPurchaseLabel2Alt">Total items: {totalString}</p>
        </div>

        <div className="checkPurchaseLabels">
          <p className="checkPurchaseLabel2Alt">Total price: ${priceString}</p>
        </div>

        <button className="checkPurchaseButton" onClick={updateCart}>Buy now</button>

      </div>

      <div className="checkoutFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={require("./../img/appicon.png")}
            alt="Image"
          />
          <TableComponent />
        </div>

        <footer className="footerCopyright">
          <p className="copyrightLabel">{copyRightLabel}</p>
        </footer>
      </div>

      {clientSecret !== "" && isOpen && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm clientSecret={ clientSecret } price={priceString} isOpen={isOpen} setIsOpen={setIsOpen} items={updatedData} username={username} address={deliveryString}/>
          </Elements>
      )}
    </div>
  );
};

export default Checkout;
