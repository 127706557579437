import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { tName, copyRightLabel } from './../extension/stringSave';
import TableComponent from "./../extension/tableComponent";
import MinibarComponent from "./../extension/minibarComponent";

import useScrollTop from "./../extension/scrollTop";

import appIcon from "./../img/appicon.png";

import "./../style/minibar.css";
import "./../style/footer.css";
import "./../style/forgot.css";

const Reset = () => {
  useScrollTop();

  return (
    <div>
       <div id="loginDiv" className="loginInput">
        <div className="forgotBox">
            <img src={appIcon} class="imageBox"/>

            <p id="forgotText" className="forgotText">
                Set a new password
            </p>
            
            <input
                id="email"
                className="forgotUsername"
                type="password"
                placeholder="New password"
                onKeyDown={(event) => {
                //   if (event.keyCode === 13) homePage(event);
                }}
            />

            <button id="loginButton" className="loginButton">
                Change password
            </button>
            
        </div>
    </div>
        <div className="loginFooterDiv">
          <div className="footerView">
            <img
              className="footerImg"
              src={require("./../img/appicon.png")}
              alt="Image"
            />
            <TableComponent />
          </div>

          <footer className="footerCopyright">
            <p className="copyrightLabel">{copyRightLabel}</p>
          </footer>
        </div>
      </div>
  );
};

export default Reset;
