import { auth, resetLink } from "./firebase";

export const sendLink = (email) => {
  return resetLink(auth, email)
      .then((userCredential) => {
        return true
      }).catch((error) => {
        // Login error
        return false
      });
};
