/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "./../style/loginDialog.css";

const AddressDialog = ({ isOpen, setIsOpen, address}) => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("You need to set a shipping address to proceed");
  const [button, setButton] = useState("Set address");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const loginRef = useRef(null);

  useEffect(() => {

    const handleAddress = () => {
        if(address != ""){
            setTitle(address)
            setButton("Change address")
        }
    }

    handleAddress();

    const handleClickOutside = (event) => {
      if (loginRef.current && !loginRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, setIsOpen, loginRef]);

  const goAddress = () => {
    let path = `/address`;
    navigate(path);
  };

  return (
    <div>
       <div className={isOpen ? "darkenDiv" : "hidden"}>
        <div className="divBox" ref={loginRef}>
          <img src={require("./../img/appicon.png")} className="divImage" width="25" height="25" />
          <p className="divLoginLabel">{title}</p>
          <button
            class="logindivButton"
            onClick={goAddress}
          >
            {button}
          </button>
          <p id="loginRegister" class="loginDialogLabel">
            or
          </p>
          <button
            class="logindivButton"
          >
            Continue with purchase
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddressDialog;