import React, { useState } from 'react';

const QuantityDropdown =  ({ cName, selectedQuantity, setSelectedQuantity }) => {
  
  const handleQuantityChange = (event) => {
    setSelectedQuantity(parseInt(event.target.value));
  };

  return (
    <select
      className={cName}
      value={selectedQuantity}
      onChange={handleQuantityChange}
    >
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
      <option value={4}>4</option>
      <option value={5}>5</option>
    </select>
  );
};

export default QuantityDropdown;
