import { database, ref, set, auth, onValue, remove } from './firebase';

export const addCart = (name, price, quantity, availability, image) => {
  // Get the current user's ID
  const userId = auth.currentUser?.uid;
  
  // Create the cartRef with the user's ID and timestamp
  const timestamp = new Date().getTime();
  const cartRef = ref(database, `cart/${userId}/${timestamp}`);
  
  const newData = {
    name: name,
    price: price,
    quantity: quantity,
    availability: availability,
    image: image
  };
  
  return set(cartRef, newData);
};

export const getCart = () => {
  // Get the current user's ID
  const userId = auth.currentUser?.uid;

  // Create the cartRef with the user's ID
  const cartRef = ref(database, `cart/${userId}`);

  // Listen for changes in the cart data
  return new Promise((resolve, reject) => {
    onValue(cartRef, (snapshot) => {
      const cartData = snapshot.val();
      if (cartData) {
        // Convert the cart object to an array
        const cartArray = Object.keys(cartData).map((key) => {
          const { name, price, quantity, availability, image } = cartData[key];
          return {
            id: key,
            name,
            price,
            quantity,
            availability,
            image
          };
        });

        // Resolve the promise with the cart array
        resolve(cartArray);
      } else {
        // If cart data is empty, resolve with an empty array
        resolve([]);
      }
    }, (error) => {
      // Reject the promise if there is an error
      reject(error);
    });
  });
};

export const deleteCart = (cartItemKey) => {
  // Get the current user's ID
  const userId = auth.currentUser?.uid;

  // Create the cartRef with the user's ID and the cart item key
  const cartItemRef = ref(database, `cart/${userId}/${cartItemKey}`);

  // Remove the cart item
  return remove(cartItemRef);
};

export const loopCart = (updatedCartArray, cartArray) => {
  const filteredCartArray = cartArray.filter((item, index) => !updatedCartArray[index]);

  return filteredCartArray;
}
