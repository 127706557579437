import React, { useEffect } from "react";

const TempMobile = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <p>Currently only available on larger screens.</p>
    </div>
  );
};

export default TempMobile;
