import React from 'react';
import { privacyString, aboutUsString, aboutUsString2, aboutUsString3, aboutUsString4, aboutUsString5, aboutUsString6, aboutUsString7, aboutUsString8} from './../extension/stringSave';
import "./../style/support.css";
export const About = () => {

  return (
    <div className="supportTextDiv">
      <p className="supportTitle2">About Us</p>
      <div className="supportContactDiv">
        <div className="supportAboutDiv">
          <p className="supportContactText2">{aboutUsString}</p>
          <p className="supportContactText2">{aboutUsString2}</p>
          <p className="supportContactText2">{aboutUsString3}</p>
          <p className="supportContactText2">{aboutUsString4}</p>
          <p className="supportContactText2">{aboutUsString5}</p>
          <p className="supportContactText2">{aboutUsString6}</p>
          <p className="supportContactText2">{aboutUsString7}</p>
          <p className="supportContactText2">{aboutUsString8}</p>
        </div>
      </div>

    </div>
  );
};

export default About;
