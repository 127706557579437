export const shawlsArray = [
  {
    name: "Ari Jama",
    description: "Elegant and intricately designed shawls inspired by Persian art and culture.",
    materials: ["Wool", "Silk", "Synthetic Blends"],
    price: 85.00, // Price in dollars
    image: require(`./../img/shawl/Persian shawls.png`),
    rating: 4.5,
    feedbackComment: ["These Ari Jamas are a true work of art! Beautifully crafted and versatile. Excellent customer service!","Love my Ari Jamas! The quality is outstanding, and they add a touch of sophistication to any outfit. Great service!","Impressed with the intricate design of the Ari Jamas. Superb quality and top-notch customer support!"],
    feedbackRating: ["5 ★", "4.5 ★", "5 ★"],
    feedbackUsername: ["Zainab A", "Ali K", "Aisha M"],
    link: 'https://buy.stripe.com/3cs5mLbOW5kbacM28g',
  },
  {
    name: "Silk Print",
    description: "Vibrant and versatile shawls showcasing the rich heritage of India's textiles.",
    materials: ["Wool", "Silk", "Synthetic Blends"],
    price: 30.00, // Price in dollars
    image: require(`./../img/shawl/Indian shawls.png`),
    rating: 4.7,
    feedbackComment: ["The Silk Prints exceeded my expectations! The colors are vibrant, and the quality is exceptional. Amazing customer support!","Absolutely love my Silk Print! Versatile, comfortable, and beautifully designed. Fantastic service!","Impressed with the rich heritage reflected in the Silk Prints. Top-quality materials and excellent customer service!"],
    feedbackRating: ["5 ★", "4.5 ★", "5 ★"],
    feedbackUsername: ["Vikram P", "Priya K", "Arjun S"],
    link: 'https://buy.stripe.com/bIY4iH2emcMDdoYcMV',
  },
  {
    name: "Kashmir Print",
    description: "Cozy and warm shawls woven in the Scottish tradition, perfect for chilly days.",
    materials: ["Wool"],
    price: 50.00, // Price in dollars
    image: require(`./../img/shawl/Scottish shawls.png`),
    rating: 4.3,
    feedbackComment: ["Kashmir Prints are perfect for cold days! Cozy and stylish. Great customer support!","Love my Kashmir Prints! The warmth and comfort are unmatched. Excellent service!","Impressed with the traditional Kashmir Print weaving of these Shawls. Wonderful quality and attentive customer service!"],
    feedbackRating: ["4 ★", "4.5 ★", "4 ★"],
    feedbackUsername: ["Euan M", "Isla W", "Lachlan C"],
    link: 'https://buy.stripe.com/5kA5mL9GOh2T4SsdR0',
  },
  {
    name: "Nepal Pashmina",
    description: "Luxurious shawls made from the finest Pashmina wool, known for their softness and style.",
    materials: ["Pashmina Wool"],
    price: 35.00, // Price in dollars
    image: require(`./../img/shawl/Pashmina shawls.png`),
    rating: 4.8,
    feedbackComment: ["Pashmina Shawls are pure luxury! Incredibly soft and stylish. Outstanding customer service!","Absolutely adore my Pashmina Shawls! The softness and quality are exceptional. Fantastic service!","Impressed with the elegance and softness of the Pashmina Shawls. Top-notch quality and attentive customer support!"],
    feedbackRating: ["5 ★", "5 ★", "4.5 ★"],
    feedbackUsername: ["Zara K", "Amit K", "Sanya M"],
    link: 'https://buy.stripe.com/aEUbL94mu3c35Ww4gr',
  },
  {
    name: "Cashmere Wool",
    description: "Exquisite shawls handcrafted in the Kashmir region, renowned for their craftsmanship.",
    materials: ["Real Fur","Wool", "Silk", "Pashmina Wool"],
    price: 115.00, // Price in dollars
    image: require(`./../img/shawl/Kashmiri shawls.png`),
    rating: 4.6,
    feedbackComment: ["Kashmiri Shawls are a true work of art! Craftsmanship is outstanding. Great customer service!","Love my Kashmiri Shawls! The attention to detail is impressive. Excellent service!","Impressed with the craftsmanship of the Kashmiri Shawls. Exceptional quality and attentive customer support!"],
    feedbackRating: ["5 ★", "4.5 ★", "5 ★"],
    feedbackUsername: ["Arif B", "Meera C", "Rajeev S"],
    link: 'https://buy.stripe.com/4gw3eDbOW13V1Gg5kw',
  },
  {
    name: "Silk Plain",
    description: "Unique shawls reflecting Tibetan culture and spirituality, often adorned with intricate patterns.",
    materials: ["Wool", "Natural Fibers"],
    price: 25.00, // Price in dollars
    image: require(`./../img/shawl/Tibetan shawls.png`),
    rating: 4.9,
    feedbackComment: ["Silk Plains are uniquely beautiful! The intricate patterns are captivating. Amazing customer support!","Absolutely love my Silk Plain! Reflects true craftsmanship. Fantastic service!","Impressed with the cultural richness of the Silk Plains. Top-notch quality and excellent customer service!"],
    feedbackRating: ["5 ★", "5 ★", "5 ★"],
    feedbackUsername: ["Tenzin W", "Dolma T", "Lobsang D"],
    link: 'https://buy.stripe.com/eVag1p7yGeULacM00d',
  },
  {
    name: "Silk Design",
    description: "Colorful and vibrant shawls representing the lively spirit of Mexican culture.",
    materials: ["Cotton", "Synthetic Blends"],
    price: 30.00, // Price in dollars
    image: require(`./../img/shawl/Mexican shawls.png`),
    rating: 4.5,
    feedbackComment: ["Silk Designs are a burst of color! Vibrant and stylish. Excellent customer service!","Love my Silk Design! Perfect for adding a pop of color. Great service!","Impressed with the vibrant colors of the Silk Designs. Wonderful quality and attentive customer support!"],
    feedbackRating: ["5 ★", "4.5 ★", "5 ★"],
    feedbackUsername: ["Maria G", "Javier R", "Elena V"],
    link: 'https://buy.stripe.com/3cscPd3iqbIzdoYaES',
  },
  // {
  //   name: "Andean Shawls",
  //   description: "Textured and warm shawls inspired by the Andes mountains and their native traditions.",
  //   materials: ["Alpaca Wool", "Synthetic Blends"],
  //   price: 100.00, // Price in dollars
  //   image: require(`./../img/shawl/Andean shawls.png`),
  //   rating: 4.7,
  //   feedbackComment: ["Andean Shawls are cozy and stylish! Love the textured feel. Great customer service!", "Absolutely love my Andean Shawls! The warmth and texture are fantastic. Excellent service!","Impressed with the warmth and texture of the Andean Shawls. Top-quality and attentive customer support!"],
  //   feedbackRating: ["5 ★", "4.5 ★", "5 ★"],
  //   feedbackUsername: ["Mateo S", "Camila C", "Diego R"],
  //   link: 'https://buy.stripe.com/28o5mL7yG5kbgBa5kz',
  // },
  // {
  //   name: "Desi Shawls",
  //   description: "Versatile shawls with a touch of Desi charm, perfect for various occasions.",
  //   materials: ["Wool", "Silk", "Cotton"],
  //   price: 10.00, // Price in dollars
  //   image: require(`./../img/shawl/Desi shawls.png`),
  //   rating: 4.8,
  //   feedbacks: ["Desi Shawls are versatile and charming! Perfect for any occasion. Great customer service!","Love my Desi Shawls! They add a touch of charm to my outfits. Fantastic service!","Impressed with the versatility and charm of the Desi Shawls. Top-notch quality and excellent customer support!"]
  // }
];

export default shawlsArray;
