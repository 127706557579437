import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { goToListing } from "./../navigate/navigations";
import { convertPrice2 } from './convertPrice';

const SearchText = ({ isOpen, setIsOpen, searchRef, listArray }) => {
  
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, setIsOpen, searchRef]);

  const checkReload = () =>{
    if (performance.navigation.type === 1) {
      // Page was reloaded
      window.scrollTo(0, 0)
    }
  }

  return (
    <div>
      <div className={isOpen ? "tableDiv" : "hidden"}>
      <table className="searchTable">
          <tbody>
            {listArray.map((array, index) => (
              <tr className="searchRow">
                <div className="searchDiv" onClick={() => {
                  if (array.name.includes("Shawl")) {
                    goToListing("Shawl", array.name, navigate);
                  } else {
                    goToListing("Carpet", array.name, navigate);
                  }
                  window.location.reload(); // Reload the page
                  checkReload();
                }}>
                    <img className="searchIcon" src={array.image}></img>
                    <p class="searchName">{array.name}</p>
                    <p class="searchPrice">${convertPrice2(array.price)}</p>
                </div>
              </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SearchText;
