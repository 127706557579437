import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { scrollToNextItem, scrollToPrevItem } from "./../extension/smoothScroll";
import { tName, random, copyRightLabel } from "./../extension/stringSave";
import { goToListing } from "./../navigate/navigations";
import { addCart } from './../firebase/cartFirebase';
import { currentUser } from "./../firebase/currentUser";
import { auth } from './../firebase/firebase';
import { shuffleArray } from './../extension/shuffleArray';
import { convertPrice } from './../extension/convertPrice';

import { shawlsArray } from "./../database/shawlsArray"
import { carpetsArray } from "./../database/carpetsArray"

import LoadDesign from "./../extension/loadDesign";
import TableComponent from "./../extension/tableComponent";
import MinibarComponent from "./../extension/minibarComponent";

import useScrollTop from "./../extension/scrollTop";

import "./../style/home.css";
import "./../style/minibar.css";
import "./../style/footer.css";

import image1 from "./../img/1.png";
import image2 from "./../img/2.png";
import image3 from "./../img/3.png";
import image4 from "./../img/4.png";

import Category from "./Category";

import LoginDialog from "./../extension/loginDialog";

const Home = () => {
  const navigate = useNavigate();
  useScrollTop();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [gridIndex, setGridIndex] = useState(0);
  const intervalRef = useRef(null);
  const [listArray, setListArray] = useState([]);
  const [recommendedArray, setRecommendedArray] = useState([]);
  const [cartTextArray, setCartTextArray] = useState([]);
  const [recommendedCartTextArray, setRecommendedCartTextArray] = useState([]);

  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const type = "items";

    const fetchData = async () => {
      const randomizedItems = shuffleArray(carpetsArray);
      const limitedItems = randomizedItems.slice(0, 6);
      setListArray(limitedItems);
      setCartTextArray(Array.from({ length: limitedItems.length }, () => "Add To Cart"));

      const randomizedItems2 = shuffleArray(shawlsArray);
      const limitedItems2 = randomizedItems2.slice(0, 6);
      setRecommendedArray(limitedItems2);
      setRecommendedCartTextArray(Array.from({ length: limitedItems2.length }, () => "Add To Cart"));
    };

    fetchData();
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    // Check if the flag is set in sessionStorage
    const visitedBefore = sessionStorage.getItem("visitedBefore");

    if (visitedBefore) {
      // User has visited before
      setIsFirstVisit(false);
      setIsLoading(false);
    } else {
      // First visit, set the flag in sessionStorage
      sessionStorage.setItem("visitedBefore", "true");
      setIsFirstVisit(false);
    }

    const handleLoad = () => {
      // Page has finished loading
      setIsLoading(false);
    };

    // Add an event listener for the load event
    window.addEventListener('load', handleLoad);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  const images = [image1, image2, image4];

  const resetTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
  };

  const linkRef = useRef(null);

  const handleButtonClick = () => {
    linkRef.current.click();
  };

  const nextItem = (containerID, imagesArray, gridName) => {
    setGridIndex((currentIndex) => (currentIndex + 1) % imagesArray.length);
    scrollToNextItem(containerID, gridName, gridIndex, currentIndex);
  };

  const prevItem = (containerID, imagesArray, gridName) => {
    setGridIndex(
      (currentIndex) => (currentIndex - 1 + imagesArray.length) % imagesArray.length
    );
    scrollToPrevItem(containerID, gridName, gridIndex, currentIndex);
  };

  const goShop = () => {
    let path = `/shop`;
    navigate(path);
  };

  const goSupport = () => {
    let path = `/support`;
    navigate(path);
  };

  const [isDelayed, setIsDelayed] = useState(false);

  useEffect(() => {
    // Simulate a delay of 1000 milliseconds (1 second)
    const delayTimeout = setTimeout(() => {
      setIsDelayed(true);
    }, 0);

    return () => clearTimeout(delayTimeout);
  }, []); // Run this effect only once on mount


  return (
    <div className="HomeDiv">
      <div className={!isFirstVisit && !isLoading ? "" : "hidden"}>
      <MinibarComponent title={tName} />

      <div className="slideshow-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            className={index === currentIndex ? "active" : ""}
            alt={`Slide ${index + 1}`}
          />
        ))}
        <a
          className="prev"
          onClick={() => {
            setCurrentIndex(
              (prevIndex) => (prevIndex - 1 + images.length) % images.length
            );
            resetTimer();
          }}
        >
          &#10094;
        </a>
        <a
          className="next"
          onClick={() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            resetTimer();
          }}
        >
          &#10095;
        </a>
      </div>

      <div className="mainDiv">
        <div className="shopDiv">
          <p className="suggestLabel">Carpets for you</p>
          <div className="grid-container" id="gridContainer">
            {listArray.map((array, index) => (
              <div
                className="grid-item"
                key={index}
                onClick={() => goToListing("Carpet", array.name, navigate)}
              >
                <img src={array.image} alt={`Carpet ${index + 1}`} />
                <div className="text-container">
                  <p className="itemText">{array.name}</p>
                  <p className="priceText">${convertPrice(array.price)}</p>
                </div>
                <button
                  className="cart-button"
                  onClick={(event) => {
                    event.stopPropagation();
                    if(currentUser){
                      addCart(
                        array.name,
                        array.price,
                        "1",
                        "in stock",
                        array.image
                      );
                      setCartTextArray((prevCartTexts) => {
                        const updatedCartTexts = [...prevCartTexts];
                        updatedCartTexts[index] = "Added";
                        return updatedCartTexts;
                      });
                    }else{
                      setIsLogin(true)
                    }
                  }}
                >
                  {cartTextArray[index]}
                </button>
              </div>
            ))}
          </div>

          <svg
            className="hidden"
            viewBox="0 0 16 16"
            onClick={() => nextItem("gridContainer", listArray, 'grid-item')}
          >
            <path
              fillRule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
            />
          </svg>

          <svg
            className="hidden"
            viewBox="0 0 16 16"
            onClick={() => prevItem("gridContainer", listArray, 'grid-item')}
          >
            <path
              fillRule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
            />
          </svg>
        </div>

        <div className="recommendDiv">
          <p className="recommendLabel">Shawls for you</p>
          <div className="grid-container" id="gridContainer2">
            {recommendedArray.map((array, index) => (
              <div
                className="grid-item"
                key={index}
                onClick={() => goToListing("Shawl", array.name, navigate)}
              >
                <img src={array.image} alt={`Shawl ${index + 1}`} />
                <div className="text-container">
                  <p className="itemText">{array.name}</p>
                  <p className="priceText">${convertPrice(array.price)}</p>
                </div>
                <button
                  className="cart-button"
                  onClick={(event) => {
                    event.stopPropagation();
                    if(currentUser){
                      addCart(
                        array.name,
                        array.price,
                        "1",
                        "in stock",
                        array.image
                      );
                      setRecommendedCartTextArray((prevCartTexts) => {
                        const updatedCartTexts = [...prevCartTexts];
                        updatedCartTexts[index] = "Added";
                        return updatedCartTexts;
                      });
                    }else{
                      setIsLogin(true)
                    }
                  }}
                >
                  {recommendedCartTextArray[index]}
                </button>
              </div>
            ))}
          </div>

          <svg
            className="hidden"
            viewBox="0 0 16 16"
            onClick={() =>
              nextItem("gridContainer2", recommendedArray, 'grid-item')
            }
          >
            <path
              fillRule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
          />
          </svg>

          <svg
            className="hidden"
            viewBox="0 0 16 16"
            onClick={() =>
              prevItem("gridContainer2", recommendedArray, 'grid-item')
            }
          >
            <path
              fillRule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
          />
          </svg>
        </div>

        <div className="footerDiv">
          <div className="footerView">
            <img
              className="footerImg"
              src={require("./../img/appicon.png")}
              alt="Image"
            />
            <TableComponent />
          </div>

          <footer className="footerCopyright">
            <p className="copyrightLabel">{copyRightLabel}</p>
            <p className="partneredLabel">Developed by Abu Nabe</p>
          </footer>
        </div>
      </div>

      <button className="shop-button" onClick={goShop}>
        Shop
      </button>

      <button className="contact-button" onClick={goSupport}>
        Support
      </button>

      <Link to="/category" ref={linkRef} />
      </div>
      
      <div className={!isFirstVisit && !isLoading ? "hidden" : ""}>
        {isDelayed && <LoadDesign setIsLoading={setIsLoading} />}
      </div>

      <LoginDialog
        isOpen={isLogin}
        setIsOpen={setIsLogin}
      />
  </div>

  );
};

export default Home;
