/** @format */

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { tName, copyRightLabel } from "./../extension/stringSave";
import { goToListing } from "./../navigate/navigations";
import { getOrder } from "./../firebase/orderFirebase";
import { auth } from "./../firebase/firebase";

import { convertPrice2 } from "./../extension/convertPrice";

import DeliveryDialog from "./DeliveryDialog";

import TableComponent from "./../extension/tableComponent";
import MinibarComponent from "./../extension/minibarComponent";

import useScrollTop from "./../extension/scrollTop";

import "./../style/minibar.css";
import "./../style/footer.css";
import "./../style/order.css";

const Order = () => {
  const navigate = useNavigate();
  const [orderArray, setOrderArray] = useState([]);

  const [isAddress, setAddress] = useState(false);
  const [addressText, setAddressText] = useState("")

  useScrollTop();

  useEffect(() => {
    const fetchData = async () => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          getOrder()
            .then((orderData) => {
              setOrderArray(orderData);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });

      return () => unsubscribe(); // Cleanup the listener when the component unmounts
    };

    fetchData();
  }, []);

  const openDelivery = (value) => {
    if (!isAddress) {
      setAddress(true);
    }
  };

  return (
    <div>
      <MinibarComponent title={tName} />

      <div className="orderDiv">
        <table className="orderTable">
          <tbody>
            {orderArray.map((orders, index) => (
              <tr className="orderRow">
                <div className="orderHeader">
                  <div className="orderHeaderDiv">
                    <div className="orderHeaderDivLeft">
                      <div className="orderHeaderLabel">
                        <p className="orderLabel">Order no.</p>
                        <p className="orderLabel2">ORDER # {orders.id}</p>
                      </div>
                    </div>

                    <div className="orderHeaderDivRight">
                      <div className="orderHeaderLabel">
                        <p className="orderLabel">Order placed</p>
                        <p className="orderLabel2">{orders.date}</p>
                      </div>

                      <div className="orderHeaderLabel">
                        <p className="orderLabel">Price</p>
                        <p className="orderLabel2">
                          ${convertPrice2(orders.price)}
                        </p>
                      </div>

                      <div className="orderHeaderLabel" onClick={() => {
                            setAddressText(orders.address)
                            openDelivery();
                          }}>
                        <p className="orderLabel">Deliver to</p>
                        <p className="orderLabelHighlight">{orders.purchase}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="orderBody">
                  <div className="orderBodyDivLeft">
                    <img
                      id="orderIcon"
                      className="orderIcon"
                      src={orders.image}
                      onClick={() => {
                        if (orders.name.includes("Shawl")) {
                          goToListing("Shawl", orders.name, navigate);
                        } else {
                          goToListing("Carpet", orders.name, navigate);
                        }
                      }}
                    ></img>

                    <div className="orderTextDiv">
                      <p
                        class="orderUsername"
                        onClick={() => {
                          if (orders.name.includes("Shawl")) {
                            goToListing("Shawl", orders.name, navigate);
                          } else {
                            goToListing("Carpet", orders.name, navigate);
                          }
                        }}
                      >
                        {orders.name}
                      </p>
                      <p class="orderArrival">{orders.delivery}</p>
                    </div>
                  </div>

                  <div className="orderBodyDivRight">
                  <button className="orderUpdate" onClick={() => window.open(orders.tracking, "_blank")}>
                    Track package
                  </button>
                    <button
                      className="orderUpdate"
                      onClick={() => {
                        const orderId = "12345"; // Replace with the actual order ID
                        const mailtoLink = `mailto:p.elegant14@gmail.com?subject=Refund Request&body=Hello, I would like to request a refund for the following item: ${orders.id}`;

                        window.open(mailtoLink, '_blank');
                      }}
                    >
                      Refund Item
                    </button>
                  </div>
                </div>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="orderFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={require("./../img/appicon.png")}
            alt="Image"
          />
          <TableComponent />
        </div>

        <footer className="footerCopyright">
          <p className="copyrightLabel">{copyRightLabel}</p>
        </footer>
      </div>

        <DeliveryDialog
          isOpen={isAddress}
          setIsOpen={setAddress}
          addressValue={addressText}
        />

    </div>
  );
};

export default Order;
