/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "./../style/loginDialog.css";

const LoginDialog = ({ isOpen, setIsOpen}) => {
  const navigate = useNavigate();

  const [button, setButton] = useState("Log in");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const loginRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (loginRef.current && !loginRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, setIsOpen, loginRef]);

  const handleSubmit = async () => {
    let path = `/login`;
    navigate(path);
  };

  const goRegister = () => {
    let path = `/register`;
    navigate(path);
  };

  const goForgot = () => {
    let path = `/forgot-password`;
    navigate(path);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Handle the Enter key press here
      handleSubmit();
    }
  };

  return (
    <div>
       <div className={isOpen ? "darkenDiv" : "hidden"}>
        <div className="divBox" ref={loginRef}>
          <img src={require("./../img/appicon.png")} className="divImage" width="25" height="25" />
          <p className="divLoginLabel">You need an account to proceed</p>
          <button
            class="logindivButton"
            disabled={isButtonDisabled}
            onClick={handleSubmit}
          >
            {button}
          </button>
          <p id="loginRegister" class="loginDialogLabel">
            or
          </p>
          <button
            class="logindivButton"
            onClick={goRegister}
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginDialog;