import React, { useState } from "react";

export const usePasswordState = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [emptyText, setEmptyText] = useState(false);

  const handlePasswordChange = (event) => {
    setEmptyText(event.target.value !== "");
  };

  return { showPassword, setShowPassword, emptyText, setEmptyText, handlePasswordChange };
};
