import React from 'react';
import { securityString, securityString2, securityString3} from './../extension/stringSave';
import "./../style/support.css";
export const Security = () => {

  return (
    <div className="supportTextDiv">
      <p className="supportTitle">Security</p>
      <p className="supportText">{securityString}</p>
      <p className="supportText">{securityString2}</p>
      <p className="supportText">{securityString3}</p>
    </div>
  );
};

export default Security;
