import { auth } from "./firebase";

// Define a variable to store the current user state
let currentUser = auth.currentUser !== null;

// Listen for authentication state changes
auth.onAuthStateChanged((user) => {
  if (user) {
    // User is signed in
    currentUser = true;
  } else {
    // User is signed out
    currentUser = false;
  }
});

// Export the currentUser variable
export { currentUser };
