/** @format */
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
  scrollToNextItem,
  scrollToPrevItem,
} from "./../extension/smoothScroll";
import { tName, copyRightLabel } from "./../extension/stringSave";
import { goToListing } from "./../navigate/navigations";
import { deliveryDate } from "./../extension/tempDate";
import { addressAuthenticated } from "./../extension/currentAddress";
import { currentUser } from "./../firebase/currentUser";
import { getUser } from "./../firebase/userFirebase";
import { addCart } from "./../firebase/cartFirebase";
import { auth } from "./../firebase/firebase";

import { importAll } from "./../database/extraImg";

import { convertPrice, convertPrice2} from './../extension/convertPrice';

import { shuffleArray } from "./../extension/shuffleArray";
import { convertToSnakeCase } from "./../extension/convertToSnakeCase";

import { shawlsArray } from "./../database/shawlsArray";
import { carpetsArray } from "./../database/carpetsArray";

import QuantityDropdown from "./../extension/quantityDropdown";
import TableComponent from "./../extension/tableComponent";
import MinibarComponent from "./../extension/minibarComponent";
import BuyDialog from "./../extension/buyDialog";

import useScrollTop from "./../extension/scrollTop";

import LoginDialog from "./../extension/loginDialog";
import AddressDialog from "./../extension/addressDialog";

import "./../style/minibar.css";
import "./../style/buy.css";
import "./../style/footer.css";

import deliveryIcon from "./../img/deliveryicon.png";
import whatsAppIcon from "./../img/whatsapplogo.png";
import returnIcon from "./../img/returnicon.png";

const Buy = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isAddress, setIsAddress] = useState(false);

  const [address, setAddress] = useState("");

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [gridIndex, setGridIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("description");
  const [cartString, setCartString] = useState("Add To Cart");
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [itemName, setItemName] = useState(null);
  const [itemDescription, setItemDescription] = useState("");
  const [itemDetails, setItemDetails] = useState([]);
  const [itemPrice, setItemPrice] = useState("");
  const [itemRating, setItemRating] = useState(5.0);
  const [itemLink, setItemLink] = useState("");
  const [itemImageArray, setItemImageArray] = useState([]);
  const [feedbackUsername, setFeedbackUsername] = useState([]);
  const [feedbackRating, setFeedbackRating] = useState([]);
  const [feedbackComment, setFeedbackComment] = useState([]);
  const [listArray, setListArray] = useState([]);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [limitImages, setLimitImages] = useState([]);

  const location = useLocation();
  const path = location.pathname;
  const pathSegments = location.pathname.split("/"); // Split the path into segments
  const middle = pathSegments[2];
  const lastWord = path.substring(path.lastIndexOf("/") + 1);

  useEffect(() => {
    const fetchData = async () => {
      const stringWithSpaces = decodeURIComponent(lastWord);
      if (middle == "Carpet") {
        const carpetNameToFind = stringWithSpaces;
        const index = carpetsArray.findIndex(
          (carpet) => carpet.name === carpetNameToFind
        );

        setItemName(carpetsArray[index].name);
        setItemDescription(carpetsArray[index].description);
        setItemDetails(carpetsArray[index].materials);
        setItemPrice(carpetsArray[index].price);
        setItemImageArray([carpetsArray[index].image]);
        setItemRating([carpetsArray[index].rating]);
        setItemLink([carpetsArray[index].link]);
        setFeedbackUsername(carpetsArray[index].feedbackUsername);
        setFeedbackRating(carpetsArray[index].feedbackRating);
        setFeedbackComment(carpetsArray[index].feedbackComment);

      } else {
        const shawlNameToFind = stringWithSpaces;
        const index = shawlsArray.findIndex(
          (carpet) => carpet.name === shawlNameToFind
        );

        setItemName(shawlsArray[index].name);
        setItemDescription(shawlsArray[index].description);
        setItemDetails(shawlsArray[index].materials);
        setItemPrice(shawlsArray[index].price);
        setItemImageArray([shawlsArray[index].image]);
        setItemRating([shawlsArray[index].rating]);
        setItemLink([shawlsArray[index].link]);
        setFeedbackUsername(shawlsArray[index].feedbackUsername);
        setFeedbackRating(shawlsArray[index].feedbackRating);
        setFeedbackComment(shawlsArray[index].feedbackComment);

      }
    };
    fetchData();

    const fetchData2 = async () => {
      if (middle == "Carpet") {
        const randomizedItems = shuffleArray(carpetsArray);
        setListArray(randomizedItems);
      } else {
        const randomizedItems = shuffleArray(shawlsArray);
        setListArray(shawlsArray);
      }
    };

    fetchData2();

    const configUser = async () => {
      if (currentUser) {
        getUser()
          .then((userData) => {
            if (userData.delivery.trim() != "") {
              setAddress(userData.delivery);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (addressAuthenticated) {
        setAddress(addressAuthenticated);
      }
    };

    configUser();

    if (itemName) {
      addImages(itemName);
    }
  }, [itemName]);

  const addImages = (path) => {
    // Clear previous images
    setAdditionalImages([]);
    setLimitImages([]);
  
    const addItemPath = convertToSnakeCase(path);
  
    // Load images and update state
    importAll(addItemPath, itemImageArray)
      .then((images) => {
        // Limit to the first 4 images
        const limitedImages = images.slice(0, 4);
  
        // Update additionalImages and limitImages
        setAdditionalImages(images);
        setLimitImages(limitedImages);
      })
      .catch((error) => {
        console.error('Error loading images:', error);
      });
  };
  
  useEffect(() => {
    // The effect will run whenever additionalImages changes
    console.log(additionalImages);
  }, [additionalImages]);
  

  const updateData = (name) => {
    if (middle == "Carpet") {
      const index = carpetsArray.findIndex((carpet) => carpet.name === name);

      setItemName(carpetsArray[index].name);
      setItemDescription(carpetsArray[index].description);
      setItemDetails(carpetsArray[index].materials);
      setItemPrice(carpetsArray[index].price);
      setItemImageArray([carpetsArray[index].image]);
      setItemRating([carpetsArray[index].rating]);
      setItemLink([carpetsArray[index].link]);
      setFeedbackUsername(carpetsArray[index].feedbackUsername);
      setFeedbackRating(carpetsArray[index].feedbackRating);
      setFeedbackComment(carpetsArray[index].feedbackComment);

      addImages(carpetsArray[index].name);
    } else {
      const index = shawlsArray.findIndex((carpet) => carpet.name === name);

      setItemName(shawlsArray[index].name);
      setItemDescription(shawlsArray[index].description);
      setItemDetails(shawlsArray[index].materials);
      setItemPrice(shawlsArray[index].price);
      setItemImageArray([shawlsArray[index].image]);
      setItemRating([shawlsArray[index].rating]);
      setItemLink([shawlsArray[index].link]);
      setFeedbackUsername(shawlsArray[index].feedbackUsername);
      setFeedbackRating(shawlsArray[index].feedbackRating);
      setFeedbackComment(shawlsArray[index].feedbackComment);

      addImages(carpetsArray[index].name);
    }
  };

  const checkReload = () => {
    // Page was reloaded
    window.scrollTo(0, 0);
  };

  useScrollTop();

  const nextItem = (containerID, imagesArray, gridName) => {
    setGridIndex((currentIndex) => (currentIndex + 1) % imagesArray.length);
    scrollToNextItem(containerID, gridName, gridIndex, currentIndex);
  };

  const prevItem = (containerID, imagesArray, gridName) => {
    setGridIndex(
      (currentIndex) =>
        (currentIndex - 1 + imagesArray.length) % imagesArray.length
    );
    scrollToPrevItem(containerID, gridName, gridIndex, currentIndex);
  };

  const handleArrowRightClick = () => {
    const nextIndex =
      currentImageIndex === limitImages.length - 1
        ? 0
        : currentImageIndex + 1;
    setCurrentImageIndex(nextIndex);
  };

  const handleArrowLeftClick = () => {
    const prevIndex =
      currentImageIndex === 0
        ? limitImages.length - 1
        : currentImageIndex - 1;
    setCurrentImageIndex(prevIndex);
  };

  const setImage = (index) => {
    setCurrentImageIndex(index);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const checkUser = () => {
    if (currentUser) {
      let path = `/checkout/${tName}`;
      navigate(path);
    }
  };

  const openDialog = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  return (
    <div>
      <MinibarComponent title={tName} />
      <div className="buyDiv">
        <div class="buyContainer">
          <div class="bLeftDiv">
            <div className="buyImgContainer">
              <svg
                className="bArrowLeft"
                viewBox="0 0 16 16"
                onClick={handleArrowLeftClick}
              >
                <path
                  fill="#5A5A5A"
                  d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                />
              </svg>

              <img
                className="buyImg"
                src={limitImages[currentImageIndex]}
                onClick={openDialog}
              ></img>

              <svg
                className="bArrowRight"
                viewBox="0 0 16 16"
                onClick={handleArrowRightClick}
              >
                <path
                  fill="#5A5A5A"
                  d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                />
              </svg>
            </div>

            <div className="buy-container" id="gridContainer">
              {limitImages.map((image, index) => (
                <div
                  className={`buy-item2 ${
                    index === currentImageIndex ? "selectedItem" : ""
                  }`}
                  key={index}
                  onClick={openDialog}
                >
                  <img src={image} alt={`Image ${index + 1}`} />
                </div>
              ))}
            </div>

            <p className="ratingName">Average ratings: {itemRating} ★</p>
            <div className="ratingDiv">
              <button className="ratingButton">Top comments</button>
              <table class="ratingTable">
                {feedbackUsername.map((username, index) => (
                  <tr>
                    <div className="ratingLine">
                      <p class="tableUsername">{username}</p>
                      <p class="tableRating">{feedbackRating[index]}</p>
                    </div>
                    <p class="tableAdditional">{feedbackComment[index]}</p>
                  </tr>
                ))}
              </table>
            </div>
          </div>
          <hr className="bLineView" />
          <div class="bRightDiv">
            <p className="buyName">{itemName}</p>
            <p className="buyPrice">${convertPrice2(itemPrice)}.00</p>

            <div className="buySelect">
              <p
                className={`buyOptionSelected ${
                  selectedOption === "description"
                    ? "buyOptionSelected"
                    : "buyOptionNone"
                }`}
                onClick={() => handleOptionChange("description")}
              >
                Description
              </p>
              <p
                className={`buyOptionSelected ${
                  selectedOption === "details"
                    ? "buyOptionSelected"
                    : "buyOptionNone"
                }`}
                onClick={() => handleOptionChange("details")}
              >
                Details
              </p>
            </div>

            <div className="buyInfoDiv">
              <div
                className={`buy1 ${
                  selectedOption === "description" ? "buy1" : "hidden"
                }`}
              >
                <p className="buyTitle1">Description</p>
                <p className="buyDescription">{itemDescription}</p>
              </div>

              <div
                className={`buy2 ${
                  selectedOption === "details" ? "buy2" : "hidden"
                }`}
              >
                <p className="buyTitle1">Details</p>

                <ul className="buyUl">
                  {itemDetails.map((itemDetail, index) => (
                    <li key={index}>{itemDetail}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="buyQuantityDiv">
              <p className="buyLeft">Quantity</p>
              <QuantityDropdown
                cName={"buyQuantity"}
                selectedQuantity={selectedQuantity}
                setSelectedQuantity={setSelectedQuantity}
              />
            </div>

            <div className="buyShopDiv">
              <button
                className="buyCart"
                onClick={(event) => {
                  event.stopPropagation(); // Prevent click event propagation
                  if (currentUser) {
                    addCart(
                      itemName,
                      itemPrice,
                      selectedQuantity,
                      "yes",
                      itemImageArray[0]
                    );
                    setCartString("Added");
                  } else {
                    setIsLogin(true);
                  }
                }}
              >
                {cartString}
              </button>
              <p className="buyOr">Or</p>
              <button
                className="buyNow"
                onClick={(event) => {
                  event.stopPropagation(); // Prevent click event propagation
                 
                  if (currentUser) {
                    addCart(
                      itemName,
                      itemPrice,
                      selectedQuantity,
                      "yes",
                      itemImageArray[0]
                    );

                    let path = `/checkout`;
                    navigate(path);
                  } else {
                    setIsLogin(true);
                  }
                    // window.open(itemLink, '_blank');
                }}
              >
                Order
              </button>
            </div>

            <div className="buyDeliveryDiv">
              <p className="buyLeft2">Delivery details</p>

              <div class="deliveryDiv">
                <img src={deliveryIcon} alt="Image" class="deliveryImg"></img>
                <p className="deliveryName">Estimated delivery</p>
              </div>
              <p className="deliveryText">{deliveryDate}</p>
            </div>

            <div className="buyDeliveryDiv">
              <div class="returnDiv">
                <img src={returnIcon} alt="Image" class="returnImg"></img>
                <p className="returnName">Return policy</p>
              </div>
              <p className="deliveryText">
                Full refund or exchange within 14 days of purchase.
              </p>
            </div>

            <hr className="contactLine" />
            <a
              className="plain-link"
              href="https://api.whatsapp.com/send?phone=%2B919906399516&data=ARBt77OYn8uYdDykXvOtApi8MpuHpmEQMHCmjHpf1Xac6Kc4A1vKHQ1MOAiBBrR0tnchRUsP0Swn3VZ5P7NyEq-6Sr7J7bD374uHkSzA-HwQ7TIMz0_uVXYAZAbtK5AUhgem2pkO8DELqNhJzKh4JcIWvA&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR1HMIIZ8gS-pS7N9VayoDuVeISrMIG80tALnlOGnTn0sSRguK7AHvZH99A"
              target="_blank"
            >
              <div class="contactDiv">
                <p class="contactName">Contact us via</p>
                <img src={whatsAppIcon} alt="Image" class="contactImg"></img>
              </div>
            </a>
          </div>
        </div>

        <div className="moreDiv">
          <p className="moreLabel">More</p>

          <div className="grid-container" id="buyContainer">
            {listArray.map((array, index) => (
              <div
                className="buy-item"
                key={index}
                onClick={() => {
                  goToListing(middle, array.name, navigate); // Navigate to the listing
                  // window.location.reload(); // Reload the page
                  updateData(array.name);
                  checkReload();
                }}
              >
                <img src={array.image} />
                <div className="text-container">
                  <p className="itemText">{array.name}</p>
                  <p className="priceText">${convertPrice(array.price)}</p>
                </div>
              </div>
            ))}
          </div>

          <svg
            className="hidden"
            viewBox="0 0 16 16"
            onClick={() => nextItem("buyContainer", listArray, "buy-item")}
          >
            <path
              fill-rule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
            />
          </svg>

          <svg
            class="hidden"
            viewBox="0 0 16 16"
            onClick={() => prevItem("buyContainer", listArray, "buy-item")}
          >
            <path
              fill-rule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
            />
          </svg>
        </div>

        <div className="buyFooterDiv">
          <div className="footerView">
            <img
              className="footerImg"
              src={require("./../img/appicon.png")}
              alt="Image"
            />
            <TableComponent />
          </div>

          <footer className="footerCopyright">
            <p className="copyrightLabel">{copyRightLabel}</p>
          </footer>
        </div>
      </div>

      <BuyDialog
        imageArray={additionalImages}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      <LoginDialog isOpen={isLogin} setIsOpen={setIsLogin} />

      <AddressDialog
        isOpen={isAddress}
        setIsOpen={setIsAddress}
        address={address}
      />
    </div>
  );
};

export default Buy;
