import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { tName, copyRightLabel } from './../extension/stringSave';
import { sendLink } from './../firebase/forgotPassword';

import TableComponent from "./../extension/tableComponent";
import MinibarComponent from "./../extension/minibarComponent";

import useScrollTop from "./../extension/scrollTop";

import appIcon from "./../img/appicon.png";

import "./../style/minibar.css";
import "./../style/footer.css";
import "./../style/forgot.css";

const Forgot = () => {
  const navigate = useNavigate();
  
  const [errorString, setErrorString] = useState("");
  useScrollTop();

  const enterButton = () => {
    const emailInput = document.getElementById('email').value.trim();

    sendLink(emailInput).then((successLogged) => {
      if(successLogged){
        let path = `/login`; // Redirect path after successful registration
        navigate(path);
      }else{
        setErrorString("email and password does not match")
      }
    })
  };
  return (
    <div>
       <div id="loginDiv" className="loginInput">
        <div className="forgotBox">
            <img src={appIcon} class="imageBox"/>

            <p id="forgotText" className="forgotText">
                Code will be sent to your email
            </p>
            
            <input
                id="email"
                className="forgotUsername"
                placeholder="Enter email"
                onKeyDown={(event) => {
                  if (event.keyCode === 13) enterButton();
                }}
            />

          <p id="errorLogin" className="errorLogin">{errorString}</p>

            <button id="loginButton" className="loginButton" onClick={enterButton}>
                Send link to email
            </button>
            
        </div>
    </div>
        <div className="loginFooterDiv">
          <div className="footerView">
            <img
              className="footerImg"
              src={require("./../img/appicon.png")}
              alt="Image"
            />
            <TableComponent />
          </div>

          <footer className="footerCopyright">
            <p className="copyrightLabel">{copyRightLabel}</p>
          </footer>
        </div>
      </div>
  );
};

export default Forgot;
