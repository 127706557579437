export const importAll = async (path, initialPath) => {
  const images = [];
  let count = 1;

  images.push(initialPath)
  while (true) {
    try {
      const image = await import(`./../img/items_pic/${path}/${count}.png`);
      if (!image.default) {
        // Break the loop if the image is undefined or empty
        break;
      }
      images.push(image.default);
      count++;
    } catch (error) {
      // Break the loop on import error
      console.error('Error loading image:', error);
      break;
    }
  }

  return images;
};
