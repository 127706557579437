import { auth, checkEmail } from "./firebase";

export const isValid = (email) => {
    return checkEmail(auth, email)
      .then((result) => {
        if (result[0] !== undefined) {
          return true;
        } else {
          return false;
        }
      });
  };
  