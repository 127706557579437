import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { tName, copyRightLabel } from './../extension/stringSave';

import About from "./../support/about"
import RefundPolicy from "./../support/refund"
import Security from "./../support/security"
import Feedback from "./../support/feedback"
import Bug from "./../support/bug"
import More from "./../support/more"
import TableComponent from "./../extension/tableComponent";
import MinibarComponent from "./../extension/minibarComponent";
import useScrollTop from "./../extension/scrollTop";
import "./../style/minibar.css";
import "./../style/lists.css";
import "./../style/support.css";

const Support = () => {
  useScrollTop();
  const [selectedLabel, setSelectedLabel] = useState(1);

  const handleLabelClick = (labelNumber) => {
    setSelectedLabel(labelNumber);
  };

  const renderSelectedSkill = (selected) => {
    if (selected === 1) {
      return <About />;
    } else if (selected === 2) {
      return <RefundPolicy />;
    } else if (selected === 3) {
      return <Security />;
    } else if (selected === 4) {
      return <Feedback />;
    } else if (selected === 5) {
      return <Bug />;
    } else if (selected === 6) {
      const contactUsURL = "https://api.whatsapp.com/send?phone=%2B919906399516&data=ARBt77OYn8uYdDykXvOtApi8MpuHpmEQMHCmjHpf1Xac6Kc4A1vKHQ1MOAiBBrR0tnchRUsP0Swn3VZ5P7NyEq-6Sr7J7bD374uHkSzA-HwQ7TIMz0_uVXYAZAbtK5AUhgem2pkO8DELqNhJzKh4JcIWvA&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR1HMIIZ8gS-pS7N9VayoDuVeISrMIG80tALnlOGnTn0sSRguK7AHvZH99A"; // Replace with your contact us page URL
      window.open(contactUsURL, "_blank");
      setSelectedLabel(1)
    }else if (selected === 7) {
      return <More />;
    }  
    // Add more conditions for additional skills if needed
    return null;
  };

  return (
    <div>
      <MinibarComponent title={tName} />

      <div className="supportDiv">
        <div className="supportDivLeft">
          <div className="supportLabelsDiv">
            <p
              className={selectedLabel === 1 ? "supportLabelClicked" : "supportLabel"}
              onClick={() => handleLabelClick(1)}>
              About us
            </p>
            <hr className="supportLine" />
            <p
              className={selectedLabel === 2 ? "supportLabelClicked" : "supportLabel"}
              onClick={() => handleLabelClick(2)}>Refund policy
            </p>
            <hr className="supportLine" />
            <p
              className={selectedLabel === 3 ? "supportLabelClicked" : "supportLabel"}
              onClick={() => handleLabelClick(3)}
            >
              Security concerns
            </p>
            <hr className="supportLine" />
            <p
              className={selectedLabel === 4 ? "supportLabelClicked" : "supportLabel"}
              onClick={() => handleLabelClick(4)}
            >
              Give feedback
            </p>
            <hr className="supportLine" />
            <p
              className={selectedLabel === 5 ? "supportLabelClicked" : "supportLabel"}
              onClick={() => handleLabelClick(5)}
            >
              Report a bug
            </p>
            <hr className="supportLine" />
            <p
              className={selectedLabel === 6 ? "supportLabelClicked" : "supportLabel"}
              onClick={() => handleLabelClick(6)}>
              Contact us
            </p>
            <hr className="supportLine" />
            <p
              className={selectedLabel === 7 ? "supportLabelClicked" : "supportLabel"}
              onClick={() => handleLabelClick(7)}>
              Company info
            </p>
            <hr className="supportLine" />
          </div>
        </div>

        {renderSelectedSkill(selectedLabel)}
      </div>

      <div className="supportFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={require("./../img/appicon.png")}
            alt="Image"
          />
          <TableComponent />
        </div>
        <footer className="footerCopyright">
            <p className="copyrightLabel">{copyRightLabel}</p>
          </footer>
      </div>
    </div>
  );
};

export default Support;
