/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getUser, updateDelivery } from "./../firebase/userFirebase";
import { auth } from "./../firebase/firebase";

import "./../style/address.css";

const Address = () => {
  const navigate = useNavigate();
  const [deliveryAddress, setDeliveryAddress] = useState("Enter your address");

  useEffect(() => {
    const fetchData = async () => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          getUser()
            .then((userData) => {
              if (userData.delivery.trim() != "") {
                setDeliveryAddress(userData.delivery);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });

      return () => unsubscribe(); // Cleanup the listener when the component unmounts
    };

    fetchData();
  }, []);

  const confirmAddress = () => {
    const deliveryInput = document.getElementById("text").value.trim();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        updateDelivery(deliveryInput);
      } else {
        const authenticationData = {
          address: deliveryInput,
        };

        // Save the data to localStorage
        localStorage.setItem(
          "deliveryAddress",
          JSON.stringify(authenticationData)
        );
       
      }
    });
    navigate(-1);
    return () => unsubscribe(); // Cleanup the listener when the component unmounts
  };
  return (
    <div>
      <div className="addressBox">
        <p className="addressTitle">Change delivery address</p>
        <input
          id="text"
          className="addressInput"
          type="address"
          name="text"
          placeholder={deliveryAddress}
          onKeyDown={(event) => {
            if (event.keyCode === 13) confirmAddress();
          }}
        />

        <button
          id="addressButton"
          className="addressButton"
          onClick={confirmAddress}
        >
          Update address
        </button>
      </div>
    </div>
  );
};

export default Address;
