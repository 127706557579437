/** @format */
import { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import { v4 as uuidv4 } from 'uuid';

import { addOrder, addAdmin, deleteOrder } from './../firebase/orderFirebase'

import "./../style/blurScreen.css";

const CheckoutForm = ({ clientSecret, price, isOpen, setIsOpen, items, username, address}) => {
  const [priceText, setPriceText] = useState(`Pay ${price}`);
  
  const [orderIds, setOrderIds] = useState([]);

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    processPayment();

    setIsLoading(true);
    setPriceText("Confirming...")

    const currentUrl = window.location.origin;
    const returnUrl = `${currentUrl}/order`;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: returnUrl,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      deletePayment();
    } else {
      
    }

    setPriceText(price)
    setIsLoading(false);
  };

  const processPayment = () => {
    const newOrderIds = [];
    for (const item of items) {
      // Generate a unique ID for each order
      const orderId = uuidv4();
      newOrderIds.push(orderId);

      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

      // Assuming addOrder function parameters are (id, name, price, person, purchase, tracking, status)
      addOrder(
        orderId,
        item.name,
        item.price,
        item.image,
        formattedDate,
        username,
        "Order being processed",
        "/process",
        address,
      );

      addAdmin(
        orderId,
        item.name,
        item.price,
        item.image,
        formattedDate,
        username,
        "Order being processed",
        "/process",
        address,
      );
    }
    setOrderIds((prevOrderIds) => [...prevOrderIds, ...newOrderIds]);
  }

  const deletePayment = () =>{
    for(const orderId in orderIds){
      deleteOrder(orderId)
    }
  }

  const paymentElementOptions = {
    layout: "tabs"
  }

  const closeDialog = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div>
        <div className={isOpen ? "darkenDiv" : "hidden"} onClick={closeDialog}></div>
        <form id="payment-form" className={isOpen ? "payment-form" : "hidden"} onSubmit={handleSubmit}>
          <p className="paymentLabel">Complete purchase</p>
          <PaymentElement id="payment-element" className="payment-element" options={paymentElementOptions}/>
          <button disabled={isLoading || !stripe || !elements} id="submit" className="submit-button">{priceText} USD</button>
        </form>
    </div>
  );
};

export default CheckoutForm;