function getNextWeekDateRange() {
    const currentDate = new Date(); // Get current date
    const oneWeekAhead = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000); // Add 7 days (1 week) to the current date
  
    const options = { month: 'long', day: 'numeric' };
    const startDate = oneWeekAhead.toLocaleDateString('en-US', options); // Format start date (one week ahead)
    const endDate = new Date(oneWeekAhead.getTime() + 24 * 60 * 60 * 1000).toLocaleDateString('en-US', options); // Format end date (one week ahead + 1 day)
  
    return `${startDate} - ${endDate}`; // Return formatted date range
  }
  
 export const deliveryDate = getNextWeekDateRange();



function getFormattedDate() {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.toLocaleString("en-US", { month: "long" });
  const year = currentDate.getFullYear();

  return `${day} ${month} ${year}`;
}

export const formattedDate = getFormattedDate();

