/** @format */

import React from "react";
import { websiteName } from "./stringSave";

const TableComponent = () => {
  const message = `Check out this website: ${websiteName}`;
  const link = {websiteName};

  const encodedMessage = encodeURIComponent(message);
  const encodedLink = encodeURIComponent(link);

  const whatsappSharingLink = `whatsapp://send?text=${encodedMessage}%20${encodedLink}`;

  return (
    <div class="container">
      <div class="row">
        <div class="footer-col">
          <h4>company</h4>
          <ul>
            <li>
              <a href="/about-us" target="_blank">About Us</a>
            </li>
            <li>
              <a href="/support">Privacy Policy</a>
            </li>
            <li>
              <a href="/support">Refund Policy</a>
            </li>
            <li>
              <a href="/support">Services Policy</a>
            </li>
          </ul>
        </div>
        <div class="footer-col">
          <h4>Explore</h4>
          <ul>
            <li>
              <a href="/shop">Shop</a>
            </li>
            <li>
              <a href="/order">Orders</a>
            </li>
            <li>
              <a href="/order">returns</a>
            </li>
            <li>
              <a href={whatsappSharingLink}>Invite friends</a>
            </li>
          </ul>
        </div>
        <div class="footer-col">
          <h4>Company info</h4>
          <ul>
            <li>
              <a href="/support">Address</a>
            </li>
            <li>
              <a href="/support">Email</a>
            </li>
            <li>
              <a href="/support">Number</a>
            </li>
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=%2B919906399516&data=ARBt77OYn8uYdDykXvOtApi8MpuHpmEQMHCmjHpf1Xac6Kc4A1vKHQ1MOAiBBrR0tnchRUsP0Swn3VZ5P7NyEq-6Sr7J7bD374uHkSzA-HwQ7TIMz0_uVXYAZAbtK5AUhgem2pkO8DELqNhJzKh4JcIWvA&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR1HMIIZ8gS-pS7N9VayoDuVeISrMIG80tALnlOGnTn0sSRguK7AHvZH99A"
                target="_blank"
              >
                WhatsApp
              </a>
            </li>
          </ul>
        </div>
        <div class="footer-col">
          <h4>Socials</h4>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/Kashmirartsemporuimofficial?mibextid=LQQJ4d"
                target="_blank"
              >
                Facebook
              </a>
            </li>
            <li>
              <a href="#">Instagram</a>
            </li>
            <li>
              <a href="#">TikTok</a>
            </li>
            <li>
              <a href="#">Youtube</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TableComponent;
