/** @format */
import React, { useState } from "react";
import { auth, checkEmail } from "./../firebase/firebase";

export const registerValidation = (email, password, fullname, phonenumber) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValidEmail = emailRegex.test(email);

  const isValidPassword = password.length > 7;
  const isFullnameEmpty = fullname.trim() === "";
  const isPhonenumberEmpty = fullname.trim() === "";

  if (!isValidEmail) {
    return "Email is not valid";
  } else if (!isValidPassword) {
    return "Password is too short";
  } else if (isFullnameEmpty) {
    return "Fullname is required";
  } else if (isPhonenumberEmpty) {
    return "Phonenumber is required";
  } else {
    return "good"
    
  }
};
