import { auth } from "./firebase";

const signOutFB = () => {
    auth
      .signOut()
      .then(() => {
        // Sign-out successful
        console.log("signed")
      })
      .catch((error) => {
        // An error occurred
        console.error("Sign-out error:", error);
      });
  };

// Export the currentUser variable
export { signOutFB };
