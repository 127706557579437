import React, { useState } from "react";
import { Link } from "react-router-dom";

import { tName, copyRightLabel } from "./../extension/stringSave";
import TableComponent from "./../extension/tableComponent";
import MinibarComponent from "./../extension/minibarComponent";

import useScrollTop from "./../extension/scrollTop";

import "./../style/minibar.css";
import "./../style/category.css";
import "./../style/footer.css";

import image1 from "./../img/category/carpets.png";
import image2 from "./../img/category/shawls.png";
import image3 from "./../img/category/carpets2.png";
import image4 from "./../img/category/shawls2.png";

const Category = () => {
  useScrollTop();

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const handleHover2 = () => {
    setIsHovered2(!isHovered2);
  };

  const carpetSrc = isHovered2 ? image3 : image1;
  const shawlSrc = isHovered ? image4 : image2; // Remove the curly braces

  return (
    <div>
      <MinibarComponent title={tName} />

      <div className="mainCategoryDiv">
        <Link className="plain-link" to="/category/carpet">
          <div className="category1" onMouseOver={handleHover2}
              onMouseOut={handleHover2}>
            <img src={carpetSrc} className="carpetImg" alt="Carpet"/>
            <button
              className="categoryButton">
              Carpets
            </button>
          </div>
        </Link>
        <Link className="plain-link" to="/category/shawl">
          <div className="category2">
            <img
              src={shawlSrc}
              className="shawlImg"
              onMouseOver={handleHover}
              onMouseOut={handleHover}
              alt="Shawl"
            />
             <button
                className="categoryButton">
                Shawls
             </button>
          </div>
        </Link>
      </div>

      <div className="footerDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={require("./../img/appicon.png")}
            alt="Image"
          />
          <TableComponent />
        </div>

        <footer className="footerCopyright">
          <p className="copyrightLabel">{copyRightLabel}</p>
        </footer>
      </div>
    </div>
  );
};

export default Category;
