import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import { tName } from './../extension/stringSave';
import TableComponent from "./../extension/tableComponent";
import MinibarComponent from "./../extension/minibarComponent";

import { updateAdmin, updateOrder } from "./../firebase/adminFirebase";

import useScrollTop from "./../extension/scrollTop";

import "./../style/minibar.css";
import "./../style/lists.css";
import "./../style/footer.css";

const AdminDialog = ({ isOpen, setIsOpen, orderValue, linkValue, setOrderValue, setLinkValue, itemID, userID, timeString}) => {

  const adminRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (adminRef.current && !adminRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, setIsOpen, adminRef]);

  const confirmData = () => {
    var deliveryInput = document.getElementById("input1").value.trim();
    var trackingInput = document.getElementById("input2").value.trim();

    if (deliveryInput !== "") {
      setOrderValue(deliveryInput);
    }
    
    if (trackingInput !== "") {
      setLinkValue(trackingInput);
    }

    updateOrder(userID, timeString, linkValue, orderValue)
    updateAdmin(itemID, linkValue, orderValue)
   

    setIsOpen(false);
  }

  return (
    <div>
      <div className={isOpen ? "darkenDiv" : "hidden"}>
        <div className="divBox" ref={adminRef}>
          <input
              id="input1"
              className="loginUsername"
              type="text"
              name="username"
              placeholder={orderValue}
              onKeyDown={(event) => {
                if (event.keyCode === 13)
                    confirmData();
              }}
            />
             <input
              id="input2"
              className="loginUsername"
              type="text"
              name="username"
              placeholder={linkValue}
              onKeyDown={(event) => {
                if (event.keyCode === 13)
                confirmData();
              }}
            />
            <button
              id="loginButton"
              className="newloginButton"
              onClick={confirmData}
            >
              Confirm Change
            </button>
        </div>
      </div>
    </div>
  );
};

export default AdminDialog;
