import { database, ref, set, auth, onValue, update } from './firebase';

export const getUser = () => {
    // Get the current user's ID
    const userId = auth.currentUser?.uid;
  
    // Create the userRef with the user's ID
    const userRef = ref(database, `users/${userId}`);
  
    // Listen for changes in the user data
    return new Promise((resolve, reject) => {
      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData) {
          // Extract the user properties
          const { delivery, email, fullname, phoneNumber } = userData;
  
          // Create a user object
          const user = {
            id: userId,
            delivery,
            email,
            fullname,
            phoneNumber,
          };
  
          // Resolve the promise with the user object
          resolve(user);
        } else {
          // If user data is empty, resolve with null
          resolve(null);
        }
      }, (error) => {
        // Reject the promise if there is an error
        reject(error);
      });
    });
  };

  export const updateDelivery = (newDelivery) => {
    // Get the current user's ID
    const userId = auth.currentUser?.uid;
  
    // Create the userRef with the user's ID
    const userRef = ref(database, `users/${userId}`);
  
    // Update the delivery value
    return update(userRef, { delivery: newDelivery });
  };