import React from 'react';
import { privacyString } from './../extension/stringSave';
import "./../style/support.css";
export const More = () => {

  return (
    <div className="supportEditDiv">
      <p className="supportTitle2">Company Info</p>
      <div className="supportContactDiv">
        <p className="supportContactText">Main Company: Kashmir arts Emporium Co., ltd</p>
        <p className="supportContactText">Address: Ground Floor River City Shopping Complex, Bangkok, Thailand, 10110</p>
      </div>

    </div>
  );
};

export default More;
