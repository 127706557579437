import { auth, signInUser } from "./firebase";

export const signIn = (email, password) => {
  return signInUser(auth, email, password)
      .then((userCredential) => {
        return true
      }).catch((error) => {
        // Login error
       return false
      });
};
