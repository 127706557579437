/** @format */

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { tName, copyRightLabel } from "./../extension/stringSave";
import { signIn } from "./../firebase/loginFirebase";

import TableComponent from "./../extension/tableComponent";
import MinibarComponent from "./../extension/minibarComponent";

import useScrollTop from "./../extension/scrollTop";

import appIcon from "./../img/appicon.png";

import {
  toggleEyePath1,
  toggleEyePath2,
  toggledEyePath1,
  toggledEyePath2,
} from "./../extension/toggleEye";

import { usePasswordState } from "./../extension/passwordToggle";

import "./../style/minibar.css";
import "./../style/footer.css";
import "./../style/login.css";

const Login = () => {
  const navigate = useNavigate();

  useScrollTop();
  const {
    showPassword,
    setShowPassword,
    emptyText,
    setEmptyText,
    handlePasswordChange,
  } = usePasswordState();
  const [errorString, setErrorString] = useState("");

  const enterButton = () => {
    const emailInput = document.getElementById("email").value.trim();
    const passwordInput = document.getElementById("password").value;

    signIn(emailInput, passwordInput).then((successLogged) => {
      if (successLogged) {
        let path = `/`; // Redirect path after successful registration
        navigate(path);
      } else {
        setErrorString("email and password does not match");
      }
    });
  };

  return (
    <div>
      <div id="loginDiv" className="loginInput">
        <div className="loginBox">
          <img src={appIcon} class="loginimageBox" />
          <input
            id="email"
            className="loginUsername"
            type="text"
            name="username"
            placeholder="Enter email"
            onKeyDown={(event) => {
              if (event.keyCode === 13) enterButton();
            }}
          />
          <input
            id="password"
            className="loginPassword"
            type={showPassword ? "password" : "text"}
            name="password"
            placeholder="Enter password"
            onChange={handlePasswordChange}
            onKeyDown={(event) => {
              if (event.keyCode === 13) enterButton();
            }}
          />
          {/* <svg
            id="toggleEye"
            fill="currentColor"
            className={emptyText ? "toggleEye" : "hidden"}
            onClick={() =>
              setShowPassword((prevShowPassword) => !prevShowPassword)
            }
          >
            <path d={showPassword ? toggledEyePath1 : toggleEyePath1} />
            <path d={showPassword ? toggledEyePath2 : toggleEyePath2} />
          </svg> */}

          <div className="loginLabelsDiv">
            <p id="loginForgot" className="loginForgot">
              <Link className="plain-link" to={"/forgotpassword"}>
                Forgot password?
              </Link>
            </p>
            <p id="loginRegister" className="newloginRegister">
              <Link className="plain-link" to={"/register"}>
                Register
              </Link>
            </p>
          </div>
          <button
            id="loginButton"
            className="newloginButton"
            onClick={enterButton}
          >
            Log in
          </button>
          <p id="errorLogin" className="newerrorLogin">
            {errorString}
          </p>

          <hr className="newlineView" />

          <button id="gSignInWrapper" className="newloginGoogle" value="submit">
            <img
              src="https://socirankfiles.xyz/projectweb/webconnect/img/google_icon.png"
              className="googleImage"
              alt="Google Icon"
            />
            Available soon
          </button>
        </div>
      </div>
      <div className="loginFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={require("./../img/appicon.png")}
            alt="Image"
          />
          <TableComponent />
        </div>

        <footer className="footerCopyright">
          <p className="copyrightLabel">{copyRightLabel}</p>
        </footer>
      </div>
    </div>
  );
};

export default Login;
