/** @format */
import React, { useEffect, useState } from "react";

import { copyRightLabel, aboutUsString, aboutUsString2, aboutUsString3, aboutUsString4, aboutUsString5, aboutUsString6, aboutUsString7, aboutUsString8} from './../extension/stringSave';
import "./../style/aboutus.css";
import TableComponent from "./../extension/tableComponent";

const AboutUs = () => {
  useEffect(() => {
    document.title = "P.Elegant - About Us"; // Change the title here
  }, []);
  return (
    <div>
      <div className="tosBackground">
        <div className="tosHeader">
          <img src={require("./../img/appicon.png")} className="Image" width="25" height="25" />
          <p className="tosHeaderLabel">About Us</p>
        </div>

        <div className="tosDiv">
          <p className="tosDivBody">{aboutUsString}</p>
          <p className="tosDivBody">{aboutUsString2}</p>
          <p className="tosDivBody">{aboutUsString3}</p>
          <p className="tosDivBody">{aboutUsString4}</p>
          <p className="tosDivBody">{aboutUsString5}</p>
          <p className="tosDivBody">{aboutUsString6}</p>
          <p className="tosDivBody">{aboutUsString7}</p>
          <p className="tosDivBody">{aboutUsString8}</p>
        </div>
      </div>
      <div className="TOSFooterDiv">
          <div className="footerView">
            <img
              className="footerImg"
              src={require("./../img/appicon.png")}
              alt="Image"
            />
            <TableComponent />
          </div>

          <footer className="footerCopyright">
            <p className="copyrightLabel">{copyRightLabel}</p>
          </footer>
        </div>
    </div>
  );
};

export default AboutUs;