/** @format */

import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { currentUser } from "./../firebase/currentUser";

import { mergeArray } from "./../database/mergeArray"

import { auth } from "./../firebase/firebase";
import { shuffleArray } from "./../extension/shuffleArray";

import LoginDialog from "./../extension/loginDialog";
import ProfileDropdown from "./../extension/profileDropdown";
import SearchText from "./../extension/searchText";

const AdminbarComponent = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const [listArray, setListArray] = useState([]);
  const [searchText, setSearchText] = useState(""); // New state to hold search text
  const profileDivRef = useRef(null);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const type = "items";

    const fetchData = async () => {
      const randomizedItems = shuffleArray(mergeArray);
      setListArray(randomizedItems);
    };

    fetchData();
  }, []);

  const checkUser = () => {
    if (currentUser) {
      let path = `/order`;
      navigate(path);
    } else {
      setIsLogin(true)
    }
  };

  const checkUser2 = () => {
    if (currentUser) {
      let path = `/checkout`;
      navigate(path);
    } else {
      setIsLogin(true)
    }
  };

  const checkUser3 = () => {
    if (!currentUser) {
       setIsLogin(true)
    } else {
      // delivery dialog
      if (!isOpen) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  };

  const openSearch = () => {
    if (!isSearch) {
      setSearch(true);
    }
  };

  // Filter the listArray based on searchText
  const filteredListArray = listArray.filter((item) => {
    return item.name.toLowerCase().includes(searchText.toLowerCase());
  });

  return (
    <div>
      <div className="mini-bar">
        <p className="title" id="title">
          <Link className="plain-link" to={"/"}>
            {title}
          </Link>
        </p>

        <p className="adminTitle" id="adminTitle">
            Admin Configuration
        </p>
        </div>
    </div>
  );
};

export default AdminbarComponent;