import { database, ref, set, auth, onValue, update, remove } from './firebase';

export const getAdmin = (itemID) => {
  // Get the current user's ID
  const userId = auth.currentUser?.uid;

  // Create the cartRef with the user's ID
  const orderRef = ref(database, `admin/${itemID}`);

  // Listen for changes in the cart data
  return new Promise((resolve, reject) => {
    onValue(orderRef, (snapshot) => {
      const orderData = snapshot.val();
      if (orderData) {
        // Convert the cart object to an array
        const orderArray = Object.keys(orderData).map((key) => {
          const { id, name, price, image, date, purchase, tracking, delivery, address} = orderData[key];
          return {
            id: key,
            key,
            id,
            name,
            price,
            image,
            date,
            purchase,
            tracking,
            delivery,
            address
          };
        });

        // Resolve the promise with the cart array
        resolve(orderArray);
      } else {
        // If cart data is empty, resolve with an empty array
        resolve([]);
      }
    }, (error) => {
      // Reject the promise if there is an error
      reject(error);
    });
  });
};

export const updateAdmin = (itemId, tracking, delivery) => {
  // Get the current user's ID
  const userId = auth.currentUser?.uid;

  // Create a reference to the specific admin item
  const adminRef = ref(database, `admin/${itemId}`);

  // Use the update method to update specific fields of the admin item
  return update(adminRef, {
    tracking: tracking,
    delivery: delivery
  })
  .then(() => {
    console.log('Admin item updated successfully');
  })
  .catch((error) => {
    console.error('Error updating admin item:', error);
    throw error; // Propagate the error to the caller
  });
};


export const updateOrder = (userID, timeString, tracking, delivery) => {
  // Get the current user's ID
  const userId = auth.currentUser?.uid;

  // Create a reference to the specific admin item
  const adminRef = ref(database, `orders/${userID}/${timeString}`);

  // Use the update method to update specific fields of the admin item
  return update(adminRef, {
    tracking: tracking,
    delivery: delivery
  })
  .then(() => {
    console.log('Admin item updated successfully');
  })
  .catch((error) => {
    console.error('Error updating admin item:', error);
    throw error; // Propagate the error to the caller
  });
};