import React, { useEffect } from "react";

const Processing = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <p>Order currently processing</p>
    </div>
  );
};

export default Processing;
