import React, { useEffect, useRef } from "react";

import "./../style/blurScreen.css";

const DeliveryDialog = ({ isOpen, setIsOpen, addressValue }) => {

  const adminRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (adminRef.current && !adminRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, setIsOpen, adminRef]);

  return (
    <div>
      <div className={isOpen ? "darkenDiv" : "hidden"}>
        <div className="deliveryBoxDiv" ref={adminRef}>
            <p className="deliveryBoxLabel">{addressValue}</p>
        </div>
      </div>
    </div>
  );
};

export default DeliveryDialog;
