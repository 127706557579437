/** @format */

import React, { useEffect, useState, useRef } from "react";

import "./../style/load_design.css";
const LoadDesign = ({setIsLoading}) => {
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
        setLoadingProgress((prevProgress) => {
          const newProgress = prevProgress + 1;
          const isComplete = newProgress === 100;

          if(newProgress === 100){
            setIsLoading(!isComplete);
          }
          return isComplete ? prevProgress : newProgress;
        });
      }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div className="loadDiv">
        <img
          className="loadImg"
          src={require("./../img/appicon.png")}
          alt="Image"
        />
        <div className="loadingBar" style={{ width: '35%' }}>
            <div className="loadingProgress" style={{ width: `${loadingProgress}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default LoadDesign;
