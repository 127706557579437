/** @format */

import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { currentUser } from "./../firebase/currentUser";

import { mergeArray } from "./../database/mergeArray"

import { auth } from "./../firebase/firebase";
import { shuffleArray } from "./../extension/shuffleArray";

import LoginDialog from "./../extension/loginDialog";
import ProfileDropdown from "./../extension/profileDropdown";
import SearchText from "./../extension/searchText";

const MinibarComponent = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const [listArray, setListArray] = useState([]);
  const [searchText, setSearchText] = useState(""); // New state to hold search text
  const profileDivRef = useRef(null);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const type = "items";

    const fetchData = async () => {
      const randomizedItems = shuffleArray(mergeArray);
      setListArray(randomizedItems);
    };

    fetchData();
  }, []);

  const checkUser = () => {
    if (currentUser) {
      let path = `/order`;
      navigate(path);
    } else {
      setIsLogin(true)
    }
  };

  const checkUser2 = () => {
    if (currentUser) {
      let path = `/checkout`;
      navigate(path);
    } else {
      setIsLogin(true)
    }
  };

  const checkUser3 = () => {
    if (!currentUser) {
       setIsLogin(true)
    } else {
      // delivery dialog
      if (!isOpen) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  };

  const openSearch = () => {
    if (!isSearch) {
      setSearch(true);
    }
  };

  // Filter the listArray based on searchText
  const filteredListArray = listArray.filter((item) => {
    return item.name.toLowerCase().includes(searchText.toLowerCase());
  });

  return (
    <div>
      <div className="mini-bar">
        <p className="title" id="title">
          <Link className="plain-link" to={"/"}>
            {title}
          </Link>
        </p>

        <div ref={searchRef} onClick={openSearch}>
          <input
            className="search-bar"
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <svg className="search-icon" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
          <SearchText
            isOpen={isSearch}
            setIsOpen={setSearch}
            searchRef={searchRef}
            listArray={filteredListArray}
          />
        </div>

        <div id="profileDiv" className="rightDiv" ref={profileDivRef}>
          <svg className="person-fill" viewBox="0 0 16 16" onClick={checkUser3}>
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
          </svg>

          <ProfileDropdown
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            profileDivRef={profileDivRef}
          />
        </div>

        <hr className="lineView" />

        <div className="rightDiv2" onClick={checkUser}>
          <svg className="bag-fill" viewBox="0 0 16 16">
            <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5z" />
          </svg>
          <p className="order-label">Orders</p>
        </div>

        <div className="rightDiv3" onClick={checkUser2}>
          <svg className="cart-fill" viewBox="0 0 16 16">
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </svg>
          <p className="cart-label">Checkout</p>
        </div>
      </div>
      <hr className="lineView2" />

      <LoginDialog
        isOpen={isLogin}
        setIsOpen={setIsLogin}
      />
    </div>
  );
};

export default MinibarComponent;
