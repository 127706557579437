import React from 'react';
import { privacyString } from './../extension/stringSave';
import "./../style/support.css";
export const RefundPolicy = () => {

  return (
    <div className="supportTextDiv">
      <p className="supportTitle">Refund Policy</p>
      <p className="supportText">{privacyString}</p>
    </div>
  );
};

export default RefundPolicy;
