import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, onValue, remove, update} from 'firebase/database';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword,fetchSignInMethodsForEmail, sendPasswordResetEmail} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);
const createUser = createUserWithEmailAndPassword;
const signInUser = signInWithEmailAndPassword;
const checkEmail = fetchSignInMethodsForEmail;
const resetLink = sendPasswordResetEmail;

const analytics = getAnalytics(app);

export {app,database, ref, set, get, auth, update, onValue, remove, createUser, signInUser, checkEmail, resetLink};