/** @format */

export const mergeArray = [
  {
    name: "Persian Carpet",
    description:
      "Exquisite Persian carpet made from high-quality materials like wool, silk, and synthetic blends.",
    materials: ["Silk on silk Irani high note", "Size 3 x 5"],
    price: "2,000", // Price in dollars
    image: require(`./../img/carpet/Persian carpet.png`),
  },
  {
    name: "Crimson Carpet",
    description:
      "A beautiful crimson-colored carpet crafted from wool, silk, and synthetic blends.",
    materials: ["Silk on silk Irani carpet", "Size 6 x 9 high note"],
    price: "10,000", // Price in dollars
    image: require(`./../img/carpet/Crimson carpet.png`),
  },
  {
    name: "Iranian Carpet",
    description:
      "Traditional Iranian carpet known for its quality wool materials.",
    materials: ["Silk on silk carpet", "Size 2/5 by 4"],
    price: "1,200", // Price in dollars
    image: require(`./../img/carpet/Iranian carpet.png`),
  },
  {
    name: "Round Carpet",
    description:
      "This exquisite round carpet is a luxurious masterpiece, expertly crafted from fine Pashmina wool. Its intricate design and impeccable quality provide a touch of elegance and sophistication to any space. Whether placed in a living room or a bedroom, this round carpet serves as a statement piece, adding warmth and style to your decor.",
    materials: ["Silk on silk round carpet", "Size 13 by 13"],
    price: "20,000", // Price in dollars
    image: require(`./../img/carpet/Round carpet.png`),
  },
  {
    name: "Medallion Carpet",
    description:
      "Elegant medallion-patterned carpet with a blend of wool, silk, and Pashmina wool.",
    materials: [],
    price: "5,000", // Price in dollars
    image: require(`./../img/carpet/Medallion carpet.png`),
  },
  {
    name: "Opulent Carpet",
    description:
      "Opulent Tibetan carpet featuring wool and natural fibers, perfect for adding a touch of luxury to your space.",
    materials: ["Wool", "Natural Fibers"],
    price: "25,000", // Price in dollars
    image: require(`./../img/carpet/Opulent carpet.png`),
  },
  {
    name: "Traditional Carpet",
    description:
      "Traditional Mexican carpet made from cotton and synthetic blends, showcasing the vibrant Mexican culture.",
    materials: ["Silk on silk High noted carpet", "Size 7 By 5"],
    price: "4,000", // Price in dollars
    image: require(`./../img/carpet/Traditional carpet.png`),
  },
  {
    name: "Treasure Carpet",
    description:
      "Exquisite Andean carpet crafted from Alpaca wool and synthetic blends, treasured for its beauty and craftsmanship.",
    materials: ["Silk on slick high note", "Size carpet 6 x 9"],
    price: "10,000", // Price in dollars
    image: require(`./../img/carpet/Treasure carpet.png`),
  },
  {
    name: "Ari Jama",
    description:
      "Elegant and intricately designed shawls inspired by Persian art and culture.",
    materials: ["Wool", "Silk", "Synthetic Blends"],
    price: 100.0, // Price in dollars
    image: require(`./../img/shawl/Persian shawls.png`),
  },
  {
    name: "Silk Print",
    description:
      "Vibrant and versatile shawls showcasing the rich heritage of India's textiles.",
    materials: ["Wool", "Silk", "Synthetic Blends"],
    price: 100.0, // Price in dollars
    image: require(`./../img/shawl/Indian shawls.png`),
  },
  {
    name: "Kashmir Print",
    description:
      "Cozy and warm shawls woven in the Scottish tradition, perfect for chilly days.",
    materials: ["Wool"],
    price: 100.0, // Price in dollars
    image: require(`./../img/shawl/Scottish shawls.png`),
  },
  {
    name: "Nepal Pashmina",
    description:
      "Luxurious shawls made from the finest Pashmina wool, known for their softness and style.",
    materials: ["Pashmina Wool"],
    price: 100.0, // Price in dollars
    image: require(`./../img/shawl/Pashmina shawls.png`),
  },
  {
    name: "Cashmere Wool",
    description:
      "Exquisite shawls handcrafted in the Kashmir region, renowned for their craftsmanship.",
    materials: ["Real Fur","Wool", "Silk", "Pashmina Wool"],
    price: 100.0, // Price in dollars
    image: require(`./../img/shawl/Kashmiri shawls.png`),
  },
  {
    name: "Silk Plain",
    description:
      "Unique shawls reflecting Tibetan culture and spirituality, often adorned with intricate patterns.",
    materials: ["Wool", "Natural Fibers"],
    price: 100.0, // Price in dollars
    image: require(`./../img/shawl/Tibetan shawls.png`),
  },
  {
    name: "Silk Design",
    description:
      "Colorful and vibrant shawls representing the lively spirit of Mexican culture.",
    materials: ["Cotton", "Synthetic Blends"],
    price: 100.0, // Price in dollars
    image: require(`./../img/shawl/Mexican shawls.png`),
  },
  {
    name: "Andean Shawls",
    description:
      "Textured and warm shawls inspired by the Andes mountains and their native traditions.",
    materials: ["Alpaca Wool", "Synthetic Blends"],
    price: 100.0, // Price in dollars
    image: require(`./../img/shawl/Andean shawls.png`),
  },
  // {
  //   name: "Desi Shawls",
  //   description: "Versatile shawls with a touch of Desi charm, perfect for various occasions.",
  //   materials: ["Wool", "Silk", "Cotton"],
  //   price: 10.00, // Price in dollars
  //   image: require(`./../img/shawl/Desi shawls.png`)
  // }
];

export default mergeArray;
