import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { tName, copyRightLabel } from "./../extension/stringSave";

import TableComponent from "./../extension/tableComponent";
import MinibarComponent from "./../extension/minibarComponent";

import useScrollTop from "./../extension/scrollTop";

import "./../style/error.css";
import "./../style/footer.css";

import errorIcon from "./../img/errorIcon.png";

const Default = () => {
  const linkRef = useRef(null);

  useScrollTop();

  return (
    <div>
      <MinibarComponent title={tName} />

      <div className="errorDiv">
        <img src={errorIcon} className="errorImg" alt="Error Icon" />
        <p className="errorLabel">Page can't be found.</p>
        <p className="errorLabel2">
          There may be an error in the link or the page no longer exists.
        </p>
        <button className="errorBuy">
        <Link className="plain-link" to={"/"} ref={linkRef}>
            Return home
        </Link>
        </button>
      </div>

      <div className="errorFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={require("./../img/appicon.png")}
            alt="Image"
          />
          <TableComponent />
        </div>
        <footer className="footerCopyright">
          <p className="copyrightLabel">{copyRightLabel}</p>
        </footer>
      </div>
    </div>
  );
};

export default Default;
