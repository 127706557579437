import { database, ref, set, auth, onValue, remove } from './firebase';

export const addOrder = (id, name, price, image, date, purchase, delivery, tracking, address) => {
  // Get the current user's ID
  const userId = auth.currentUser?.uid;
  
  // Create the cartRef with the user's ID and timestamp
  const timestamp = new Date().getTime();
  const dataRef = ref(database, `orders/${userId}/${timestamp}`);
  
  const newData = {
    id: id,
    name: name,
    date: date,
    price: price,
    image: image,
    purchase: purchase,
    tracking: tracking,
    delivery: delivery,
    address: address
  };
  
  return set(dataRef, newData);
};

export const addAdmin = (id, name, price, image, date, purchase, delivery, tracking, address) => {
  // Get the current user's ID
  const userId = auth.currentUser?.uid;
  
  // Create the cartRef with the user's ID and timestamp
  const timestamp = new Date().getTime();
  const dataRef = ref(database, `admin/${id}/${timestamp}`);
  
  const newData = {
    id: userId,
    name: name,
    date: date,
    price: price,
    image: image,
    purchase: purchase,
    tracking: tracking,
    delivery: delivery,
    address: address,
  };
  
  return set(dataRef, newData);
};

export const getOrder = () => {
  // Get the current user's ID
  const userId = auth.currentUser?.uid;

  // Create the cartRef with the user's ID
  const orderRef = ref(database, `orders/${userId}`);

  // Listen for changes in the cart data
  return new Promise((resolve, reject) => {
    onValue(orderRef, (snapshot) => {
      const orderData = snapshot.val();
      if (orderData) {
        // Convert the cart object to an array
        const orderArray = Object.keys(orderData).map((key) => {
          const { id, name, price, image, date, purchase, tracking, delivery, address} = orderData[key];
          return {
            id: key,
            id,
            name,
            price,
            image,
            date,
            purchase,
            tracking,
            delivery,
            address
          };
        });

        // Resolve the promise with the cart array
        resolve(orderArray);
      } else {
        // If cart data is empty, resolve with an empty array
        resolve([]);
      }
    }, (error) => {
      // Reject the promise if there is an error
      reject(error);
    });
  });
};

export const deleteOrder = (orderId) => {
  // Get the current user's ID
  const userId = auth.currentUser?.uid;

  // Create the orderRef with the user's ID and orderId
  const orderRef = ref(database, `orders/${userId}/${orderId}`);

  // Remove the order
  return remove(orderRef);
};