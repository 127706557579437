import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { copyRightLabel } from './../extension/stringSave';
import { usePasswordState } from './../extension/passwordToggle';
import { registerUser } from './../firebase/registerFirebase';
import { isValid } from './../firebase/emailAuthentication';
import { registerValidation } from './../extension/registerError';
import { toggleEyePath1, toggleEyePath2, toggledEyePath1, toggledEyePath2 } from './../extension/toggleEye';

import TableComponent from "./../extension/tableComponent";
import MinibarComponent from "./../extension/minibarComponent";

import useScrollTop from "./../extension/scrollTop";

import appIcon from "./../img/appicon.png";

import "./../style/minibar.css";
import "./../style/footer.css";
import "./../style/register.css";
import './../firebase/registerFirebase.js';

const Register = () => {
  const navigate = useNavigate();

  useScrollTop();
  const [errorString, setErrorString] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { showPassword, setShowPassword, emptyText, setEmptyText, handlePasswordChange } = usePasswordState();

  const enterButton = () => {
    const emailInput = document.getElementById('email').value.trim();
    const fullnameInput = document.getElementById('username').value.trim();
    const passwordInput = document.getElementById('password').value;
    const phonenumberInput = document.getElementById('number').value.trim();

    const validationMessage = registerValidation(emailInput, passwordInput, fullnameInput, phonenumberInput);
    if (validationMessage === "good") {
      // Check if email already exists
      setIsLoading(true)
      isValid(emailInput)
        .then((exists) => {
          if (!exists) {
            // Register the user
            return registerUser(fullnameInput, emailInput, passwordInput, phonenumberInput);
          } else {
            throw new Error("Email already exists");
          }
        })
        .then(() => {
          let path = `/`; // Redirect path after successful registration
          navigate(path);
        })
        .catch((error) => {
          setErrorString(error.message);
        }).finally(() => {
          setIsLoading(false); 
        });
    } else {
      setErrorString(validationMessage);
    }
  };

  return (
    <div style={{ cursor: isLoading ? "wait" : "auto" }}>
      <div id="loginDiv" className="loginInput">
        <div className="registerBox">
          <img src={appIcon} className="imageBox" />
          <input
            id="username"
            className="registerUsername"
            type="text"
            name="username"
            placeholder="Enter fullname"
            onKeyDown={(event) => {
              if (event.keyCode === 13) enterButton(event);
            }}
          />
          <input
            id="email"
            className="registerEmail"
            type="text"
            name="username"
            placeholder="Enter email"
            onKeyDown={(event) => {
              if (event.keyCode === 13) enterButton(event);
            }}
          />
          <input
            id="password"
            className="registerPassword"
            type={showPassword ? "password" : "text"}
            name="password"
            placeholder="Enter password"
            onChange={handlePasswordChange}
            onKeyDown={(event) => {
              if (event.keyCode === 13) enterButton(event);
            }}
          />
          <input
            id="number"
            className="registerPhone"
            type="number"
            name="number"
            placeholder="Enter phone number"
            onKeyDown={(event) => {
              if (event.keyCode === 13) enterButton(event);
            }}
          />

          <svg id="toggleEye" fill="currentColor" className={emptyText ? "registerToggleEye" : "hidden"} onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)}>
            <path d={showPassword ? toggledEyePath1 : toggleEyePath1} />
            <path d={showPassword ? toggledEyePath2 : toggleEyePath2} />
          </svg>

          <p id="errorRegister" className="errorRegister">
            {errorString}
          </p>

          <button id="registerButton" className="registerButton" onClick={enterButton}>
            Register
          </button>
        </div>
      </div>
      <div className="loginFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={require("./../img/appicon.png")}
            alt="Image"
          />
          <TableComponent />
        </div>

        <footer className="footerCopyright">
          <p className="copyrightLabel">
            {copyRightLabel}
          </p>
        </footer>
      </div>
    </div>
  );
};

export default Register;
