import React, { useEffect, useState, useRef } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";

import { convertPrice2 } from "./../extension/convertPrice";

import { getAdmin } from "./../firebase/adminFirebase";

import { tName } from './../extension/stringSave';
import TableComponent from "./../extension/tableComponent";
import AdminbarComponent from "./../extension/adminbarComponent";

import AdminDialog from "./AdminDialog";
import DeliveryDialog from "./DeliveryDialog";

import useScrollTop from "./../extension/scrollTop";
import SearchText from "./../extension/searchText";

import "./../style/admin.css";
import "./../style/adminbar.css";
import "./../style/lists.css";
import "./../style/footer.css";

const Admin = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const [isChange, setChange] = useState(false);
  const [isAddress, setAddress] = useState(false);
  const searchRef = useRef(null);
  const [searchText, setSearchText] = useState(""); // New state to hold search text

  const [orderText, setOrderText] = useState("Order being processed")
  const [linkText, setLinkText] = useState("Change tracking link")
  const [addressText, setAddressText] = useState("")
  const [itemText, setItemText] = useState("");
  const [itemFound, setItemFound] = useState(false);

  const [orderID, setOrderID] = useState("");
  const [userID, setUserID] = useState("");
  const [timeString, setTimeString] = useState("");

  const [orders, setOrderArray] = useState([])
  
  useScrollTop();

  const configOrder = (orderID) => {
    getAdmin(orderID)
    .then((orderData) => {
      
      if(orderData.length > 0){
        setOrderArray(orderData);
        setItemFound(true)
        setOrderText(orderData[0].delivery)
       
        setUserID(orderData[0].id)
        setTimeString(orderData[0].key)
        setAddressText(orderData[0].address)
        if(orderData[0].tracking.trim() == "/process"){
          setLinkText("Change tracking link")
        }else{
          setLinkText(orderData[0].tracking)
        }
      }else{
        setItemFound(false)
        setItemText("No Item Found")
      }
    })
    .catch((error) => {
      setItemFound(false)
      setItemText("No Item Found")
      console.error(error);
    });
  } 
  
  const openSearch = () => {
    if (!isSearch) {
      setSearch(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // Perform the action you want when Enter key is pressed
      setOrderID(e.target.value.trim())
      configOrder(e.target.value.trim())
    }
  };

    
  const openChange = () => {
    if (!isChange) {
      setChange(true);
    }
  };
  
  const openDelivery = () => {
    if (!isAddress) {
      setAddress(true);
    }
  };

  return (
    <div>
      <AdminbarComponent title={tName} />
  
      <div ref={searchRef} onClick={openSearch}>
        <input
          className="admin-bar"
          type="text"
          placeholder="Enter product id "
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </div>
  
      {itemFound ? (
        <div className="adminDiv">
          <div className="orderHeader">
            <div className="orderHeaderDiv">
              <div className="orderHeaderDivLeft">
                <div className="orderHeaderLabel">
                  <p className="orderLabel">Order no.</p>
                  <p className="orderLabel2">ORDER # {orderID}</p>
                </div>
              </div>
  
              <div className="orderHeaderDivRight">
                <div className="orderHeaderLabel">
                  <p className="orderLabel">Order placed</p>
                  <p className="orderLabel2">{orders[0].date}</p>
                </div>
  
                <div className="orderHeaderLabel">
                  <p className="orderLabel">Price</p>
                  <p className="orderLabel2">${convertPrice2(orders[0].price)}</p>
                </div>
  
                <div className="orderHeaderLabel" onClick={() => {
                    openDelivery();
                  }}>
                  <p className="orderLabel">Deliver to</p>
                  <p className="orderLabelHighlight">{orders[0].purchase}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="orderBody">
            <div className="orderBodyDivLeft">
              <img
                id="orderIcon"
                className="orderIcon"
                src={orders[0].image}
                alt="Order Icon"
                onClick={() => {
                  
                }}
              />
              <div className="orderTextDiv">
                <p
                  className="orderUsername"
                  onClick={() => {
                    // Handle click on username
                  }}
                >
                  {orders[0].name}
                </p>
                <p className="orderArrival">{orderText}</p>
              </div>
            </div>
            <div className="orderBodyDivRight">
              <button
                className="adminUpdate"
                onClick={() => openChange()}>
                Change Tracking Link
              </button>
              <button
                className="adminUpdate"
                onClick={() => {
                  openChange()
                }}>
                Change Delivery Status
              </button>
            </div>
          </div>
        </div>
      ) : (
        <p className="adminText">{itemText}</p>
      )}
  
      <AdminDialog
        isOpen={isChange}
        setIsOpen={setChange}
        orderValue={orderText}
        linkValue={linkText}
        setOrderValue={setOrderText}
        setLinkValue={setLinkText}
        itemID={orderID}
        useID={userID}
        timeString={timeString}
      />

      <DeliveryDialog
        isOpen={isAddress}
        setIsOpen={setAddress}
        addressValue={addressText}
      />

    </div>
  );
  
};

export default Admin;
