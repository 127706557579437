export const tName = "P.Elegant";
export const websiteName = "pelegant.com";
export const copyRightLabel = "© 2023 P.Elegant, Ltd. Partnership";
export const random = "save";
export const privacyString = "At P.Elegant, we value and respect your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website or engage with our services. By accessing or using our website or services, you agree to the terms and practices described in this Privacy Policy. Collection of Personal Information: We may collect personal information such as your name, email address, shipping address, phone number, and payment details when you place an order or create an account on our website. We use this information to process your orders, provide customer support, and improve our services. Use of Personal Information: We use the personal information collected to fulfill your orders, communicate with you about your order status, send promotional offers or newsletters if you opt-in, and enhance your overall experience with us. We do not sell or share your personal information with third parties for their marketing purposes. Return and Refund Policy: We offer a 14-day return window for eligible items. If you are unsatisfied with your purchase, you may return the item within 14 days of receiving it for a full refund. However, please note the following conditions: The item must be unused, in its original condition, and in the original packaging. Return shipping costs may apply and will be deducted from the refund amount. Before the delivery dispatch, you can request a refund, and it will be fully processed. Once the delivery is dispatched or the item is with you, in order to get a refund, you must contact us and provide proof of any item faults or defects.";
export const securityString = "Security Measures: We take appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. We use industry-standard encryption technology and secure servers to ensure the confidentiality and integrity of your data."
export const securityString2 = "Cookies and Tracking Technologies: We may use cookies and similar tracking technologies to enhance your browsing experience and collect information about how you use our website. You can adjust your browser settings to reject cookies, but this may limit certain features or functionality of our website."
export const securityString3 = "Updates to the Privacy Policy: We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We encourage you to review this Privacy Policy periodically to stay informed about how we handle your personal information."
export const aboutUsString = "Welcome to P.elegant, the ultimate destination for discerning connoisseurs of high-quality carpets and exquisite shawls. Established in the early 2000s, P.elegant has been synonymous with luxury, elegance, and exceptional craftsmanship, offering a curated collection of premium textiles that elevate living spaces and wardrobes alike."
export const aboutUsString2 = "Uncompromising Quality: At P.elegant, we are committed to showcasing the finest selection of carpets and shawls that embody unparalleled quality and sophistication. Each piece in our collection is meticulously crafted by skilled artisans and renowned manufacturers, ensuring that our customers receive nothing short of exceptional products that stand the test of time."
export const aboutUsString3 = "Artistry in Carpets: Our exquisite range of carpets exemplifies artistry and attention to detail, featuring a diverse array of designs inspired by both timeless traditions and contemporary aesthetics. From opulent oriental motifs to modern geometric patterns, our carpets are crafted from luxurious materials such as wool, silk, and cotton, exhibiting rich textures, vivid hues, and enduring resilience. Whether you seek a statement piece for your home or an elegant addition to your office space, P.elegant offers a captivating array of carpets to cater to every taste and interior style."
export const aboutUsString4 = "Elegance in Shawls: Complementing our renowned carpet collection is a captivating array of shawls that exude elegance and refinement. Our shawls, crafted from the finest fabrics including cashmere, merino wool, and silk, are a testament to luxurious comfort and style. Whether it's an exquisitely embroidered pashmina or a delicately woven stole, each shawl in our collection is a timeless expression of craftsmanship and sophistication, perfect for adding that extra touch of glamour to any ensemble."
export const aboutUsString5 = "Personalized Service: At P.elegant, we place a premium on customer satisfaction and personalized service. Our dedicated team of experts is devoted to guiding our clients through our exquisite range of products, ensuring that they find the perfect carpet or shawl to enrich their living spaces or wardrobes. Whether you seek a custom-sized rug for a specific area or a unique shawl for a special occasion, our knowledgeable staff is committed to providing tailored assistance and expert advice at every step."
export const aboutUsString6 = "Unrivaled Access: In addition to our physical showroom, P.elegant offers a seamless online experience, where customers can explore our exquisite collection from the comfort of their homes. With secure and efficient delivery services, we ensure that our clients receive their orders promptly and in pristine condition, regardless of their location."
export const aboutUsString7 = "A Legacy of Excellence: As P.elegant continues to uphold its position as a purveyor of exceptional carpets and shawls, we remain dedicated to expanding our range and staying ahead of the industry's latest trends and innovations. From exclusive collaborations to the introduction of new designs, we are committed to exceeding our customers' expectations and providing them with unparalleled access to the finest array of textiles and accessories."
export const aboutUsString8 = "P.elegant invites you to immerse yourself in a world of timeless sophistication, where every carpet and shawl tells a story of refined elegance and enduring quality. Discover the epitome of luxury with P.elegant, where exceptional craftsmanship meets unparalleled elegance, transforming living spaces and wardrobes with unparalleled style and allure."

export const stripePath = "https://createpayment-3qytb56nda-uc.a.run.app"