export const carpetsArray = [
  {
    name: "Persian Carpet",
    description: "Exquisite Persian carpet made from high-quality materials like wool, silk, and synthetic blends.",
    materials: ["Silk on silk Irani high note", "Size 3 x 5"],
    price: 2000, // Price in dollars
    image: require(`./../img/carpet/Persian carpet.png`),
    rating: 4.5,
    feedbackComment: ['This Persian Carpet exceeded my expectations! Exceptional quality and craftsmanship. Amazing customer support too!', 'Absolutely love my Persian Carpet! The intricate design and premium materials make it a standout. Great customer service!', 'Impressed with the Persian Carpet is exquisite details. The customer support team was incredibly helpful and responsive. Highly recommended!'],
    feedbackRating: ["5 ★", "4.5 ★", "5 ★"],
    feedbackUsername: ["Daniel K", "Layla P", "Carlos R"],
    link: 'https://buy.stripe.com/14k6qP2em6ofgBa144',
  },
  {
    name: "Crimson Carpet",
    description: "A beautiful crimson-colored carpet crafted from wool, silk, and synthetic blends.",
    materials: ["Silk on silk Irani carpet", "Size 6 x 9 high note"],
    price: 10000, // Price in dollars
    image: require(`./../img/carpet/Crimson carpet.png`),
    rating: 4.8,
    feedbackRating: ["4.8 ★", "4.5 ★", "5 ★"],
    feedbackUsername: ["Mary K", "Mateo M", "Natasha G"],
    feedbackComment: ["Crimson Carpet adds a touch of luxury! The color is rich, and the quality is superb. Great customer support!", "Absolutely stunning Crimson Carpet! Craftsmanship and design are impressive. Excellent service!", "Impressed with the luxurious feel of the Crimson Carpet. Outstanding quality and attentive customer support!"],
    link: 'https://buy.stripe.com/28o16v7yGfYP4Ss3cd',
  },
  {
    name: "Iranian Carpet",
    description: "Traditional Iranian carpet known for its quality wool materials.",
    materials: ["Silk on silk carpet", "Size 2/5 by 4"],
    price: 1200, // Price in dollars
    image: require(`./../img/carpet/Iranian carpet.png`),
    rating: 4.3,
    feedbackRating: ["4.3 ★", "4.5 ★", "4 ★"],
    feedbackUsername: ["Ravi K", "Anaya P", "Sofia S"],
    feedbackComment: ["Iranian Carpet is a classic beauty! Traditional and well-crafted. Great customer support!", "Love my Iranian Carpet! The quality of the wool is outstanding. Excellent service!", "Impressed with the traditional charm of the Iranian Carpet. Wonderful quality and attentive customer service!"],
    link: 'https://buy.stripe.com/14k2az3iqdQH84E002',
  },
  {
    name: "Round Carpet",
    description: "This exquisite round carpet is a luxurious masterpiece, expertly crafted from fine Pashmina wool. Its intricate design and impeccable quality provide a touch of elegance and sophistication to any space. Whether placed in a living room or a bedroom, this round carpet serves as a statement piece, adding warmth and style to your decor.",
    materials: ["Silk on silk round carpet", "Size 13 by 13"],
    price: 20000, // Price in dollars
    image: require(`./../img/carpet/Round carpet.png`),
    rating: 4.7,
    feedbackRating: ["5 ★", "4.5 ★", "5 ★"],
    feedbackUsername: ["Elijah T", "Mia F", "Javier M"],
    feedbackComment: ["Round Carpet is a masterpiece! The design and quality are exceptional. Amazing customer support!", "Absolutely love my Round Carpet! It adds a touch of sophistication to my space. Fantastic service!", "Impressed with the elegance of the Round Carpet. Top-notch quality and excellent customer service!"],
    link: 'https://buy.stripe.com/00g6qP2emaEvbgQ9AD',
  },
  {
    name: "Medallion Carpet",
    description: "Elegant medallion-patterned carpet with a blend of wool, silk, and Pashmina wool.",
    materials: [],
    price: 5000, // Price in dollars
    image: require(`./../img/carpet/Medallion carpet.png`),
    rating: 4.2,
    feedbackRating: ["4.2 ★", "4 ★", "4.5 ★"],
    feedbackUsername: ["Aaliyah K", "Leo M", "Amara K"],
    feedbackComment: ["Medallion Carpet is an elegant choice! The medallion pattern is exquisite. Great customer support!", "Love my Medallion Carpet! The blend of wool and silk is perfect. Excellent service!", "Impressed with the craftsmanship of the Medallion Carpet. Exceptional quality and attentive customer support!"],
    link: 'https://buy.stripe.com/eVa2azf18cMD0Cc6os',
  },
  {
    name: "Opulent Carpet",
    description: "Opulent Tibetan carpet featuring wool and natural fibers, perfect for adding a touch of luxury to your space.",
    materials: ["Wool", "Natural Fibers"],
    price: 25000, // Price in dollars
    image: require(`./../img/carpet/Opulent carpet.png`),
    rating: 4.9,
    feedbackRating: ["5 ★", "4.5 ★", "5 ★"],
    feedbackUsername: ["Eva L", "Kai P", "Mila R"],
    feedbackComment: ["Opulent Carpet is truly opulent! Luxurious feel and top-notch quality. Amazing customer support!", "Absolutely love my Opulent Carpet! It adds a touch of luxury to my home. Fantastic service!", "Impressed with the opulence of the Opulent Carpet. Wonderful quality and attentive customer support!"],
    link: 'https://buy.stripe.com/5kA16v9GOaEv1Gg8wB',
  },
  {
    name: "Traditional Carpet",
    description: "Traditional Mexican carpet made from cotton and synthetic blends, showcasing the vibrant Mexican culture.",
    materials: ["Silk on silk High noted carpet", "Size 7 By 5"],
    price: 4000, // Price in dollars
    image: require(`./../img/carpet/Traditional carpet.png`),
    rating: 4.4,
    feedbackRating: ["4.5 ★", "4 ★", "4.7 ★"],
    feedbackUsername: ["Luisa C", "Cesar D", "Isabella R"],
    feedbackComment: ["Traditional Carpet is a burst of culture! Vibrant and well-crafted. Great customer support!", "Love my Traditional Carpet! It showcases the beauty of Mexican culture. Excellent service!", "Impressed with the vibrant colors of the Traditional Carpet. Outstanding quality and attentive customer support!"],
    link: 'https://buy.stripe.com/28o8yXf18cMD70A6ou',
  },
  {
    name: "Treasure Carpet",
    description: "Exquisite Andean carpet crafted from Alpaca wool and synthetic blends, treasured for its beauty and craftsmanship.",
    materials: ["Silk on slick high note", "Size carpet 6 x 9"],
    price: 10000, // Price in dollars
    image: require(`./../img/carpet/Treasure carpet.png`),
    rating: 4.6,
    feedbackRating: ["4.8 ★", "4.5 ★", "4.7 ★"],
    feedbackUsername: ["Elena M", "Jaxon K", "Nia P"],
    feedbackComment: ["Treasure Carpet is a treasure indeed! Beautiful craftsmanship and design. Amazing customer support!", "Absolutely love my Treasure Carpet! It adds warmth and beauty to my space. Fantastic service!", "Impressed with the beauty and craftsmanship of the Treasure Carpet. Top-notch quality and excellent customer support!"],
    link: 'https://buy.stripe.com/bIYbL93iqbIzdoYcMT',
  },
];

export default carpetsArray;
