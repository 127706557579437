import { app, database, ref, set, auth, createUser } from "./firebase";

export const registerUser = (fullname, email, password, phonenumber) => {
  // Register the email first
  createUser(auth,email, password)
    .then((userCredential) => {
      // Email registration successful
      const user = userCredential.user;

      // Create user data in the database
      const dataRef = ref(database, `users/${user.uid}`);
      const newData = {
        fullname: fullname,
        email: email,
        phonenumber: phonenumber,
        delivery: ""
      };

      return set(dataRef, newData);
    })
    .then(() => {
      console.log("User data added successfully");
    })
    .catch((error) => {
      console.error("Error registering email or adding user data:", error);
    });
};
