import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';

import './App.css';

import appIcon from './img/appicon.png';

import Home from './design/Home';
import Category from './design/Category';
import Lists from './design/Lists';
import Buy from './design/Buy';
import Error from './design/Error';
import Order from './design/Order';
import Processing from './design/Processing.js';
import Checkout from './design/Checkout';
import Login from './design/Login';
import Forgot from './design/Forgot';
import Reset from './design/Reset';
import Register from './design/Register';
import Support from './design/Support';
import Address from './design/Address.js';
import AboutUs from './design/AboutUs.js';
import Mobile from './design/tempMobile.js';
import Admin from './design/Admin.js';

function App() {
  useEffect(() => {
    document.title = 'Shop online - P.Elegant'; // Change the title here
  }, []);

  const isMedia = window.matchMedia('(max-width: 900px)').matches;

  return (
    <Router>
      <div className="App"></div>

      {isMedia ? (
        <Routes>
          <Route path="/" element={<Mobile />} />
          <Route path="*" element={<Mobile />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<Category />} />
          <Route path="/category/:id" element={<Lists />} />
          <Route path="/listing/:id/:otherId" element={<Buy />} />
          <Route path="/order" element={<Order />} />
          <Route path="/process" element={<Processing />} />
          <Route path="/support" element={<Support />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/forgotpassword" element={<Forgot />} />
          <Route path="/resetpassword/:id" element={<Reset />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/address" element={<Address />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/U2FsdGVkX18wiG7oB4+RlFf+/xKmbK9AYQRz6N2oDzA=" element={<Admin />} />
          <Route path="*" element={<Error />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
