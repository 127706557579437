import { database, ref, set, auth } from './firebase';

export const addFeedback = (text) => {
  // Get the current user's ID
  const userId = auth.currentUser?.uid;
  
  // Create the cartRef with the user's ID and timestamp
  const timestamp = new Date().getTime();
  const dataRef = ref(database, `feedback/${userId}/${timestamp}`);
  
  const newData = {
    text: text,
  };
  
  return set(dataRef, newData);
};

export const addBug = (text) => {
  // Get the current user's ID
  const userId = auth.currentUser?.uid;
  
  // Create the cartRef with the user's ID and timestamp
  const timestamp = new Date().getTime();
  const dataRef = ref(database, `bug/${userId}/${timestamp}`);
  
  const newData = {
    text: text,
  };
  
  return set(dataRef, newData);
};