/** @format */

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { tName, copyRightLabel } from "./../extension/stringSave";
import { goToListing } from "./../navigate/navigations";
import { auth } from "./../firebase/firebase";

import { convertPrice } from './../extension/convertPrice';

import { carpetsArray } from "./../database/carpetsArray"
import { shawlsArray } from "./../database/shawlsArray"

import TableComponent from "./../extension/tableComponent";
import MinibarComponent from "./../extension/minibarComponent";

import useScrollTop from "./../extension/scrollTop";

import "./../style/minibar.css";
import "./../style/lists.css";
import "./../style/footer.css";

const Lists = () => {
  const navigate = useNavigate();
  const [listArray, setListArray] = useState([]);

  useScrollTop();

  const location = useLocation();
  const path = location.pathname;
  const lastWord = path.substring(path.lastIndexOf("/") + 1);
  const titleWord = lastWord.charAt(0).toUpperCase() + lastWord.slice(1);

  useEffect(() => {
    const fetchData = async () => {
      console.log(titleWord)
      if(titleWord == "Carpet"){
        setListArray(carpetsArray)
      }else{
          setListArray(shawlsArray)
      }
      // getItemsFromDatabase(type)
      //   .then((items) => {
      //     // Handle the retrieved items here
      //     setListArray(items);
      //   })
      //   .catch((error) => {
      //     // Handle any errors that occurred during retrieval
      //     console.error("Error retrieving items:", error);
      //   });
    };

    fetchData();
  }, []);

  return (
    <div>
      <MinibarComponent title={`${tName} - ${titleWord}`} />

      <div className="listDiv">
        <div className="list-container" id="gridContainer">
          {listArray.map((array, index) => (
            <div
              className="list-item"
              key={index}
              onClick={() => goToListing(titleWord, array.name, navigate)}
            >
              <img src={array.image} />
              <div className="text-container">
                <p className="listText">{array.name}</p>
                <p className="listText2">${convertPrice(array.price)}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="listFooterDiv">
          <div className="footerView">
            <img
              className="footerImg"
              src={require("./../img/appicon.png")}
              alt="Image"
            />
            <TableComponent />
          </div>

          <footer className="footerCopyright">
            <p className="copyrightLabel">{copyRightLabel}</p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Lists;
